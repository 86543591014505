import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layout/defaultlayout";
import ContentNav from "../../datanavcontent";
import './about.css';
import ImagesInput from "../../components/imageInput/ImageInputMulti";
import Loader from "../../components/loader";
import axios from "axios";
import { Axios } from "../../components/axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../../components/axios/BASE_URL";
function About() {
  const [editLoading, setEditLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [img, setImage] = useState(null);
  const [imgUrl, setImgUrl] = useState();
  const [headerLogoUpload, setHeaderLogoUpload] = useState(null);
  const [footerLogoUpload, setFooterLogoUpload] = useState(null);
  const [aboutLogoUpload, setAboutLogoUpload] = useState(null);
  const [uploadedImage, setUploadedImage] = useState({ url: "", type: "" });
  const [loader, setLoader] = useState(null);
  const [text, setText] = useState({ title: "", type: "" });
  // footerLogoUpload
  const [aboutSiteData, setAboutSiteData] = useState({
    site_name: 'سماء التنميه الأمنيه',
    site_logo: require("../../assets/logo/logo.png"),
    location:
      '4004 جنوبا الى احد رفيده وشمالا إلى خميس مشيط حي العرق الجنوبي8197 ',
    phone: '017234567',
    email: 'info@samasecuritysystems.com',
  });

  const getData = async () => {
    const data = await axios.get(
      "https://samasecuritysystems.com/apis/samaaAdmin/admin/site_info/index.php"
    );

    setAboutSiteData(data?.data?.message);
  };

  useEffect(() => {
    getData();
  }, []);
  const getAboutSite = () => {
    setPageLoading(false);
  };

  const handleEdit = async ({ key, value }) => {
    setLoader(true);
    await Axios({
      method: "POST",
      url: "https://samasecuritysystems.com/apis/samaaAdmin/admin/site_info/update_text.php",
      data: {
        key,
        value,
      },
    })
      .then((res) => {
        if (res.status == "success") {
          getData();
          setUploadedImage({ url: "", type: "" });
          setImage({ url: "", type: "" });
          setImgUrl({ url: "", type: "" });
          setText({ title: "", type: "" });
          toast.success(res?.message);
        } else {
          toast.error(res?.message);
        }
      })
      .finally(setLoader(false));
  };

  const handleUplaoadImage = () => {
    const formData = new FormData();
    formData.append('image', img?.image);
    axios
      .post(BASE_URL + 'upload.php', formData)
      .then((res) => {
        // console.log(res)
        console.log(res.data.message);
        if (res.data.status == 'success') {
          setUploadedImage({ url: res.data.message, type: img?.type });
          toast.success('تم الرفع');
        } else if (res.data.status == 'error') {
          toast.error(res.data.message);
        } else {
          toast.error('حدث خطأ ما');
        }
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    getAboutSite();
  }, []);

  return (
    <div className="h-container con-h">
      <DefaultLayout
        children={
          <div className="childs">
            <div className="breadCrumbsFlex">
              <ContentNav head={"عن الموقع"} />
            </div>
            <div className="about_page">
              <form>
                <div className="inputField">
                  <label htmlFor="">صوره الموقع</label>
                  <label htmlFor="imglogo">
                    <img
                      style={{ width: '100px' }}
                      src={aboutSiteData[0]?.header_logo}
                      alt=""
                    />
                    <input
                      onChange={(e) => {
                        setImage({ image: e.target.files[0], type: "header" });
                      }}
                      id="imglogo"
                      type="file"
                    />
                  </label>
                </div>
                <div className="rowDiv">
                  {" "}
                  {headerLogoUpload ? (
                    <Loader />
                  ) : img?.type == "header" ? (
                    <span
                      disabled={footerLogoUpload}
                      style={{ width: "fit-content" }}
                      onClick={() => {
                        handleUplaoadImage();
                      }}
                      className="btn btn-success m-auto d-block"
                    >
                      رفع الصورة
                    </span>
                  ) : null}
                  {img?.type == "header" ? (
                    <span
                      className="btn btn-primary"
                      onClick={() => {
                        handleEdit({
                          key: "header_logo",
                          value: uploadedImage?.url,
                        });
                      }}
                    >
                      حفظ
                    </span>
                  ) : null}
                </div>
                <div className="inputField">
                  <label htmlFor="">صوره الفوتار</label>
                  <label htmlFor="imglogo">
                    <img
                      style={{ width: '100px' }}
                      src={aboutSiteData[0]?.footer_logo}
                      alt=""
                    />
                    <input
                      onChange={(e) => {
                        setImage({ image: e.target.files[0], type: "footer" });
                      }}
                      id="imglogo"
                      type="file"
                    />
                  </label>
                </div>
                <div className="rowDiv">
                  {" "}
                  {footerLogoUpload ? (
                    <Loader />
                  ) : img?.type == "footer" ? (
                    <span
                      disabled={footerLogoUpload}
                      style={{ width: "fit-content" }}
                      onClick={() => {
                        handleUplaoadImage();
                      }}
                      className="btn btn-success m-auto d-block"
                    >
                      رفع الصورة
                    </span>
                  ) : null}
                  {img?.type == "footer" ? (
                    <span
                      className="btn btn-primary"
                      onClick={() => {
                        handleEdit({
                          key: "footer_logo",
                          value: uploadedImage?.url,
                        });
                      }}
                    >
                      حفظ
                    </span>
                  ) : null}
                </div>
                <div className="inputField">
                  <label htmlFor="">صوره ال about</label>
                  <label htmlFor="imglogo">
                    <img
                      style={{ width: '100px' }}
                      src={aboutSiteData[0]?.about_image}
                      alt=""
                    />
                    <input
                      onChange={(e) => {
                        setImage({ image: e.target.files[0], type: "about" });
                      }}
                      id="imglogo"
                      type="file"
                    />
                  </label>
                </div>
                <div className="rowDiv">
                  {" "}
                  {aboutLogoUpload ? (
                    <Loader />
                  ) : img?.type == "about" ? (
                    <span
                      disabled={aboutLogoUpload}
                      style={{ width: "fit-content" }}
                      onClick={() => {
                        handleUplaoadImage();
                      }}
                      className="btn btn-success m-auto d-block"
                    >
                      رفع الصورة
                    </span>
                  ) : null}
                  {img?.type == "about" ? (
                    <span
                      className="btn btn-primary"
                      onClick={() => {
                        handleEdit({
                          key: "about_image",
                          value: uploadedImage?.url,
                        });
                      }}
                    >
                      حفظ
                    </span>
                  ) : null}
                </div>
                <div className="inputField">
                  <label htmlFor="">إسم الموقع</label>
                  <input
                    type="text"
                    defaultValue={aboutSiteData[0]?.site_name}
                    onChange={(e) => {
                      setText({
                        title: e.target.value,
                        type: "site_name",
                      });
                    }}
                  />
                </div>
                {text?.type == "site_name" ? (
                  <span
                    className="btn btn-primary"
                    onClick={() => {
                      handleEdit({
                        key: "site_name",
                        value: text?.title,
                      });
                    }}
                  >
                    حفظ
                  </span>
                ) : null}
                <div className="inputField">
                  <label htmlFor="">العنوان</label>
                  <input
                    type="text"
                    defaultValue={aboutSiteData[0]?.address}
                    onChange={(e) => {
                      setText({
                        title: e.target.value,
                        type: "address",
                      });
                    }}
                  />
                </div>
                {text?.type == "address" ? (
                  <span
                    className="btn btn-primary"
                    onClick={() => {
                      handleEdit({
                        key: "address",
                        value: text?.title,
                      });
                    }}
                  >
                    حفظ
                  </span>
                ) : null}
                <div className="inputField">
                  <label htmlFor="">رقم الهاتف</label>
                  <input
                    type="text"
                    defaultValue={aboutSiteData[0]?.phone}
                    onChange={(e) => {
                      setText({
                        title: e.target.value,
                        type: "phone",
                      });
                    }}
                  />
                </div>
                {text?.type == "phone" ? (
                  <span
                    className="btn btn-primary"
                    onClick={() => {
                      handleEdit({
                        key: "phone",
                        value: text?.title,
                      });
                    }}
                  >
                    حفظ
                  </span>
                ) : null}
                <div className="inputField">
                  <label htmlFor="">البريد الإلكترونى</label>
                  <input
                    type="text"
                    defaultValue={aboutSiteData[0]?.email}
                    onChange={(e) => {
                      setText({
                        title: e.target.value,
                        type: "email",
                      });
                    }}
                  />
                </div>
                {text?.type == "email" ? (
                  <span
                    className="btn btn-primary"
                    onClick={() => {
                      handleEdit({
                        key: "email",
                        value: text?.title,
                      });
                    }}
                  >
                    حفظ
                  </span>
                ) : null}
                <div className="inputField">
                  <label htmlFor="">الكوبي رايت</label>
                  <input
                    type="text"
                    defaultValue={aboutSiteData[0]?.copy_right}
                    onChange={(e) => {
                      setText({
                        title: e.target.value,
                        type: "copy_right",
                      });
                    }}
                  />
                </div>
                {text?.type == "copy_right" ? (
                  <span
                    className="btn btn-primary"
                    onClick={() => {
                      handleEdit({
                        key: "copy_right",
                        value: text?.title,
                      });
                    }}
                  >
                    حفظ
                  </span>
                ) : null}
              </form>
            </div>
          </div>
        }
      />
    </div>
  );
}

export default About;
