/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layout/defaultlayout";
import ContentNav from "../../datanavcontent";
import Table from "../../components/table";
import "./style.css";

import "./style.css";

import Modal from "../../components/modal";
import axios from "axios";
import { productsData } from "../CategoryProducts/data";
import { Axios } from "../../components/axios";

export const product_headers = [
  {
    label: "رقم المنتج",
    dataIndex: "id",
    type:'children',
    children:({row,header})=>{

    }
  },
  {
    label: "اسم المنتج",
    dataIndex: "title",
  },
  {
    label: "وصف المنتج",
    dataIndex: "title",
  },
];

function OrderHistory() {
  let localData=localStorage.getItem("samaa_admin");
  let userData=localData&&JSON.parse(localData);
  const [pageLoading,setPageLoading]=useState(false);
  const [data, setData] = useState();
  const [showOrder, setShowOrder] = useState(false);
  const getOrders = async () => {
    setPageLoading(true)
    const data_send={
      "access_token":userData.access_token,
      "id":userData.id,
    }
    Axios({
      method: 'POST',
      url: "https://samasecuritysystems.com/apis/samaaAdmin/admin/orders/index.php",
      data:data_send
    })
    .then((res) => {
        console.log(res);
        if (res.status == 'success') {
          setData(res.message);
        } else if(res.status=='error'){
            localStorage.removeItem('samaa_admin');
            window.location.reload();
        }
    })
    .finally(() => {
      setPageLoading(false);
    });
  };
  useEffect(() => {
    getOrders();
  }, []);

  const prodHead = [
    {
      label: "رقم المنتج",
      dataIndex: "id",
    },
    {
      label: "رقم المنتج",
      dataIndex: "id",
      type:'children',
      children: ({ row, headers }) => {
        return (
          <img style={{maxWidth:'100px',maxHeight:'100px'}} src={row.image} alt="" />
        );
      },
    },
    {
      label: "اسم المنتج",
      dataIndex: "name",
    },
    {
      label: "وصف المنتج",
      dataIndex: "description",
    },
  ];

  const headers = [
    {
      label: "رقم الطلب",
      dataIndex: "id",
    },
    {
      label: "إسم الشخص",
      dataIndex: "f_name",
    },
    {
      label: "رقم تليفون الشخص",
      dataIndex: "phone",
    },
    {
      label: "البريد الالكتروني",
      dataIndex: "email",
    },
    {
      label: "العنوان",
      dataIndex: "address",
    },
    {
      label: "الملاحظة",
      dataIndex: "notes",
    },
    {
      label: "أوامر",
      type: "actions",
      actions: [
        {
          label: " عرض تفاصيل الطلب  ",
          action: setShowOrder,
        },
      ],
    },
  ];
  return (
    <div className="h-container con-h">
      <DefaultLayout
        children={
          <div className="childs">
            <ContentNav head={"سجل الطلبات"} />
            <Table headers={headers} body={data} classess={["table-tc"]} />
            <Modal
              open={showOrder}
              toggle={setShowOrder}
              headerTitle={"الطلب رقم " + showOrder?.item?.id}
              children={
                <div className="order_details">
                  {" "}
                  <p>
                    <span>اسم العميل:</span>
                    <pre>{showOrder?.item?.f_name+" "+showOrder?.item?.l_name}</pre>
                  </p>
                  <p>
                    <span>رقم تليفون العميل:</span>
                    <pre>
                      <span>{showOrder?.item?.phone}</span>
                      <a
                        href={"tel:" + showOrder?.item?.phone}
                        className="btn"
                      ></a>
                    </pre>
                  </p>
                  <p>
                    <span>البريد الالكتروني :</span>
                    <pre>
                      <span>{showOrder?.item?.email}</span>{" "}
                      <a
                        href={"mailto:" + showOrder?.item?.email}
                        className="btn"
                      ></a>
                    </pre>
                  </p>
                  <p>
                    <span>العنوان</span> <pre>{showOrder?.item?.address}</pre>
                  </p>
                  <p>
                    <span>الملاحظات</span> <pre>{showOrder?.item?.notes}</pre>
                  </p>
                  {
                    showOrder?.item?.product_data&&
                    (
                      <p className="order">
                    <span> تفاصيل الطلب:</span>
                    <Table
                      headers={prodHead}
                      body={[showOrder?.item?.product_data]}
                      classess={["table-tc"]}
                      showPagination={false}
                    />
                  </p>
                    )
                  }
                </div>
              }
            />
          </div>
        }
      />
    </div>
  );
}

export default OrderHistory;
