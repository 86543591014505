export const BASE_URL = "https://samasecuritysystems.com/apis/samaaAdmin/admin/";
export const BASES_ROUTES = {
  admin: "admin/",
  user: "user/",
};
export const API_ROUTES = {
  auth: "auth/login/index.php",
  image: "upload.php",
  pdfs: "uploud_pdf.php",
  categories: {
    BASE_ROUTE: "category/",
    ROUTES: {
      getAll: "index.php",
      add: "add",
      edit: "edit",
    },
  },
};
