import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layout/defaultlayout";
import ContentNav from "../../datanavcontent";
import Table from "../../components/table";
import "./style.css";

import "./style.css";
import Modal from "../../components/modal";
import axios from "axios";
import { Axios } from "../../components/axios";
import { API_ROUTES, BASE_URL } from "../../components/axios/BASE_URL";
import ImageInput from "../../components/imageInput/ImageInput";
import { toast } from "react-toastify";
import Loader from "../../components/loader";
import { useNavigate } from "react-router-dom";
import { edit, hidde, show } from "../../navbar/sidenav/svgImages";

function Services() {
  let localData=localStorage.getItem("samaa_admin");
  let userData=localData&&JSON.parse(localData);
  const [id,setId]=useState('');
  const [addLoading,setAddLoading]=useState(false);
  const [showStatusModal,setShowStatusModal]=useState(false)
  const [newService,setnewService]=useState({
    title:'',
    description:'',
    image:'',
  });
  const [changeLoading,setChangeLoading]=useState(false)
  const [rowData,setRowData]=useState({})
  const [uploadloading,setuploadloading]=useState(false)
  const [pageLoading,setPageLoading]=useState(false)
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [openAdd, setOpenAdd] = useState();
  const [openEdit, setOpenEdit] = useState();
  const [categoryData, setCategoryData] = useState({});
  const [img, setImg] = useState(null);
  const [loader, setLoader] = useState(null);

  const headers = [
    {
      label: "رقم الخدمة",
      dataIndex: "id",
    },
    {
      label: "عنوان الخدمة",
      dataIndex: "title",
    },
    {
      label: "وصف الخدمة",
      dataIndex: "description",
    },
    {
      label: "صورة الخدمة",
      dataIndex: "image",
      type: "children",
      children: ({ row }) => {
        return (
          <img style={{ width: '100px' }} src={row?.image} alt="" />
        );
      },
    },
    {
      label: "الأدوات",
      dataIndex:'',
      type: 'children',
      children: ({ row, header }) => {
        return (
          <div style={{display:'flex',alignItems:'center',justifyContent:'center',gap:'4px',flexWrap:'wrap'}}>
            <span
              onClick={()=>{
                setRowData({...row,id:row.id})
                console.log(row)
                setId(row.id)
                setOpenEdit(true)
              }}
              style={{color:'green',cursor:'pointer'}}>{edit}</span>
            {
              row.hidden=='0'
              ?
              (<span onClick={()=>{
                setRowData(row)
                setShowStatusModal(true)
              }} style={{color:'green',cursor:'pointer'}}>{show}</span>)
              :
              (<span onClick={()=>{
                setRowData(row)
                setShowStatusModal(true)
              }} style={{color:'red',cursor:'pointer'}}>{hidde}</span>)
            }
          </div>
        );
      },
    },
  ];

  const handleChangetStatus = async () => {
    setChangeLoading(true);
    const data_send={
      ...rowData,
      access_token:userData.access_token,
      admin_id:userData.id,
    }
    // console.log(data_send)
    await Axios({
      method: "POST",
      url: "https://samasecuritysystems.com/apis/samaaAdmin/admin/services/update_status.php",
      data: data_send,
    })
      .then((res) => {
        // console.log(res)
        if(res.status=='success'){
          toast.success(res.message);
          getServices();
          setShowStatusModal(false)
        }
        else if(res.status=='error'){
          toast.error(res.message)
        }
        else {
          toast.error('حدث خطأ ما')
        }
      })
      .finally(setChangeLoading(false));
  };

  const getServices = () => {
    // setData(productsData);
    setPageLoading(true)
    const data_send={
      "access_token":userData.access_token,
    "id":userData.id,
    "user_type":"admin"
    }
    Axios({
      method: 'POST',
      url: "https://samasecuritysystems.com/apis/samaaAdmin/admin/services/index.php",
      data:data_send
    })
    .then((res) => {
        // console.log(res)
        if (res.status == 'success') {
          setData(res.message);
        } else if(res.status=='error'){
            // localStorage.removeItem('samaa_admin');
            // window.location.reload();
        }
    })
    .finally(() => {
      setPageLoading(false);
    });
  };


  const addServices = async () => {
    setLoader(true);
    setAddLoading(true)
    const data_send={
      ...newService,
      access_token:userData.access_token,
      admin_id:userData.id
    }
    console.log(data_send)
    Axios({
      method: "POST",
      url:"https://samasecuritysystems.com/apis/samaaAdmin/admin/services/add_service.php",
      data: {
        ...data_send
      },
    })
      .then((res) => {
        console.log(res)
        if (res.status == "success") {
          setOpenAdd(false);
          getServices()
          toast.success(res.message);
        } else {
          toast.error(res?.message);
        }
      })
      .finally(()=>{
        setLoader(false)
        setAddLoading(false)
      });
  };

  const editCategory = async () => {
    setAddLoading(true)
    const data_send={
      id:id,
      image:rowData.image,
      description:rowData?.description,
      title:rowData.title,
      access_token:userData.access_token,
      admin_id:userData.id
    }
    console.log(data_send)
    Axios({
      method: "POST",
      url:"https://samasecuritysystems.com/apis/samaaAdmin/admin/services/update_service.php",
      data: {
        ...data_send
      },
    })
      .then((res) => {
        console.log(res)
        if (res.status == "success") {
          setOpenEdit(false);
          getServices()
          toast.success(res.message);
        } else {
          toast.error(res?.message);
        }
      })
      .finally(setAddLoading(false));
  };

  const handleUplaoadImage=()=>{
    setuploadloading(true)
    const formData=new FormData();
    formData.append('image',img);
    axios.post(BASE_URL+'upload.php',formData)
    .then((res)=>{
      if(res.data.status=='success'){
        setnewService({...newService,image:res.data.message});
        toast.success('تم الرفع')
      }
      else if(res.data.status=='error'){
        toast.error(res.data.message)
      }
      else{
        toast.error('حدث خطأ ما')
      }
    }).catch(e=>console.log(e))
    .finally(()=>{
    setuploadloading(false)
    })
  }
  const handleUplaoadImageafterEdit=()=>{
    setuploadloading(true)
    const formData=new FormData();
    formData.append('image',img);
    axios.post(BASE_URL+'upload.php',formData)
    .then((res)=>{
      console.log(res.data.message)
      if(res.data.status=='success'){
        setRowData({...rowData,image:res.data.message});
        toast.success('تم الرفع')
      }
      else if(res.data.status=='error'){
        toast.error(res.data.message)
      }
      else{
        toast.error('حدث خطأ ما')
      }
    }).catch(e=>console.log(e))
    .finally(()=>{
    setuploadloading(false)
    })
  }

  useEffect(() => {
    // getServices();
    getServices()
  }, []);
  return (
    <div className="h-container con-h">
      <DefaultLayout
        children={
          <div className="childs">
            <div className="breadCrumbsFlex">
              <ContentNav head={"الخدمات"} />
              <button
                className="btn addbtn btn-primary"
                onClick={() => setOpenAdd(true)}
              >
                <img
                  src="https://res.cloudinary.com/duovxefh6/image/upload/v1698247380/insert_mox69q.png"
                  alt=""
                />
                <span>إضافة خدمة</span>
              </button>
            </div>
            <Table headers={headers} body={data} classess={["table-tc"]} />
            <Modal
              open={openAdd}
              toggle={setOpenAdd}
              headerTitle={"إضافة خدمة"}
              children={
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    return !addLoading ? addServices() : null;
                  }}
                >
                  <div className="inputField">
                    <label htmlFor="">عنوان الخدمة</label>
                    <input
                      type="text"
                      onChange={(e) => {
                        setnewService({
                          ...newService,
                          title: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="inputField">
                    <label htmlFor="">وصف الخدمة</label>
                    <textarea
                      type="text"
                      onChange={(e) => {
                        setnewService({
                          ...newService,
                          description: e.target.value,
                        });
                      }}
                    ></textarea>
                  </div>
                  <div className="input_field mb-2">
                    <label htmlFor="">صورة الخدمه</label>
                    <input type="file" className="form-control" onChange={(e)=>{
                      setImg(e.target.files[0])
                    }} />
                    <div>
                    {
                      uploadloading?(
                        <Loader/>
                      )
                      :
                      (
                        <button
                      onClick={()=>{
                        handleUplaoadImage()
                      }}
                        style={{width:'100px'}}
                      className="btn mt-2 btn-success">رفع</button>
                      )
                    }
                  </div>
                  </div>
                  {!addLoading ? (
                    <button disabled={uploadloading} className="btn btn-success"> إضافة</button>
                  ) : (
                    <Loader />
                  )}
                </form>
              }
            />

            <Modal
              open={openEdit}
              toggle={setOpenEdit}
              headerTitle={"تعديل خدمة"}
              children={
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    return !addLoading ? editCategory() : null;
                  }}
                >
                  <div className="inputField">
                    <label htmlFor="">عنوان الخدمة</label>
                    <input
                      type="text"
                      value={rowData?.title}
                      onChange={(e) => {
                        setRowData({
                          ...rowData,
                          title: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="inputField">
                    <label htmlFor="">وصف الخدمة</label>
                    <textarea
                      type="text"
                      value={rowData?.description}
                      onChange={(e) => {
                        setRowData({
                          ...rowData,
                          description: e.target.value,
                        });
                      }}
                    ></textarea>
                  </div>
                  <div className="input_field mb-2">
                    <label htmlFor="">صورة المنتج</label>
                    <input type="file" className="form-control" onChange={(e)=>{
                      setImg(e.target.files[0])
                    }} />
                    <div>
                    {
                      uploadloading?(
                        <Loader/>
                      )
                      :
                      (
                        <button
                      onClick={()=>{
                        handleUplaoadImageafterEdit()
                      }}
                        style={{width:'100px'}}
                      className="btn mt-2 btn-success">رفع</button>
                      )
                    }
                  </div>
                  </div>
                  {!addLoading ? (
                    <button disabled={uploadloading} className="btn btn-success"> تعديل</button>
                  ) : (
                    <Loader />
                  )}
                </form>
              }
            />
          </div>
        }
      />
      <Modal
        open={showStatusModal}
        toggle={setShowStatusModal}
        headerTitle={"تغيير حالة الظهور"}
        children={
          <>
            <p>هل تريد حقا {rowData.hidden=='0'?'إخفاء':'إظهار'} هذا الخدمه</p>
            <div className="actions align-items-center d-flex gap-2">
              {
                changeLoading?(
                  <Loader/>
                )
                :
                (
                  <button onClick={()=>{
                    handleChangetStatus()
                  }} className="btn btn-success">نعم</button>
                )
              }
              <button onClick={()=>{
                setShowStatusModal(false)
              }} className="btn btn-danger">لا</button>
            </div>
          </>
        }
      />
    </div>
  );
}

export default Services;
