import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layout/defaultlayout";
import ContentNav from "../../datanavcontent";
import Table from "../../components/table";
import Modal from "../../components/modal";
import axios from "axios";
import { Axios } from "../../components/axios";
import { API_ROUTES, BASE_URL } from "../../components/axios/BASE_URL";
import ImageInput from "../../components/imageInput/ImageInput";
import { toast } from "react-toastify";
import Loader from "../../components/loader";
import ImagesInput from "../../components/imageInput/ImageInputMulti";
import { guranteesData } from "./data";
import { edit, hidde, show } from "../../navbar/sidenav/svgImages";

function Gurantees() {
  const [changeLoading, setChangeLoading] = useState(false);
  const [uploadloading, setuploadloading] = useState(false);
  const [img, setImg] = useState(null);
  const [rowData, setRowData] = useState({});
  const [pageLoading, setPageLoading] = useState(false);
  let localData = localStorage.getItem("samaa_admin");
  let userData = localData && JSON.parse(localData);
  const [data, setData] = useState();
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [openAdd, setOpenAdd] = useState();
  const [openEdit, setOpenEdit] = useState();
  const [guranteeData, setGuranteeData] = useState({});
  const [imgs, setImgs] = useState(null);
  const [loader, setLoader] = useState(null);
  const [addLoading, setAddLoading] = useState(false);
  const [gurantees, setFeaturs] = useState([{ id: 0, key: "", value: "" }]);
  const [comfortable, setComfortable] = useState([{ id: 0, text: "" }]);
  const [newGurantee, setNewGurantee] = useState({
    name: '',
    description: '',
    image: '',
  });
  const headers = [
    {
      label: "رقم الضمان",
      dataIndex: "id",
    },
    {
      label: "صورة الضمان",
      dataIndex: "image",
      type: 'children',
      children: ({ row, headers }) => {
        return <img src={row.image} alt="" />;
      },
    },
    {
      label: "إسم الضمان",
      dataIndex: "name",
    },
    {
      label: "وصف الضمان",
      dataIndex: "description",
    },
    {
      label: "أمر",
      dataIndex: "action",
      type: 'children',
      children: ({ row, headers }) => {
        return (
          <>
            <span
              style={{ color: 'green', cursor: 'pointer' }}
              onClick={() => {
                setGuranteeData(row);
                setOpenEdit(true);
                setRowData(row);
              }}
            >
              {edit}
            </span>
            {row.hidden == '0' ? (
              <span
                onClick={() => {
                  setRowData(row);
                  setShowStatusModal(true);
                }}
                style={{ color: 'green', cursor: 'pointer' }}
              >
                {show}
              </span>
            ) : (
              <span
                onClick={() => {
                  setRowData(row);
                  setShowStatusModal(true);
                }}
                style={{ color: 'red', cursor: 'pointer' }}
              >
                {hidde}
              </span>
            )}
          </>
        );
      },
    },
    // {
    //   label: "الأدوات",
    //   type: "actions",
    //   actions: [
    //     {
    //       label: " تعديل ",
    //       action: ({ item }) => {
    //         setGuranteeData(item);
    //         setOpenEdit(item);
    //       },
    //     },
    //   ],
    // },
  ];
  // Get Categories
  const getgurantees = () => {
    setPageLoading(true);
    const data_send = {
      access_token: userData.access_token,
      id: userData.id,
    };
    Axios({
      method: 'POST',
      url: "https://samasecuritysystems.com/apis/samaaAdmin/admin/gurantee/index.php",
      data: data_send,
    })
      .then((res) => {
        console.log(res);
        if (res.status == 'success') {
          setData(res.message);
        }
      })
      .finally(() => {
        setPageLoading(false);
      });
  };

  const editgurantee = async () => {
    setPageLoading(true);
    setAddLoading(true);
    const data_send = {
      ...rowData,
      access_token: userData.access_token,
      admin_id: userData.id,
    };
    Axios({
      method: 'POST',
      url: "https://samasecuritysystems.com/apis/samaaAdmin/admin/gurantee/update_gurantee.php",
      data: data_send,
    })
      .then((res) => {
        console.log(res);
        if (res.status == 'success') {
          setData(res.message);
          setOpenEdit(false);
          toast.success(res.message);
          getgurantees();
        } else if (res.status == 'error') {
          toast.error(res.message);
        }
      })
      .finally(() => {
        setAddLoading(false);
      });
  };

  const handleAddGurantee = async () => {
    setAddLoading(true);
    const data_send = {
      ...newGurantee,
      access_token: userData.access_token,
      admin_id: userData.id,
    };
    await Axios({
      method: "POST",
      url: "https://samasecuritysystems.com/apis/samaaAdmin/admin/gurantee/add_gurantee.php",
      data: data_send,
    })
      .then((res) => {
        console.log(res);
        if (res.status == "success") {
          getgurantees();
          setOpenAdd(false);
          toast.success(res.message);
        } else {
          toast.error(res?.message);
        }
      })
      .finally(setAddLoading(false));
  };

  const handleUplaoadImage = () => {
    setuploadloading(true);
    const formData = new FormData();
    formData.append('image', img);
    axios
      .post(BASE_URL + 'upload.php', formData)
      .then((res) => {
        console.log(res.data.message);
        if (res.data.status == 'success') {
          setNewGurantee({ ...newGurantee, image: res.data.message });
          toast.success('تم الرفع');
        } else if (res.data.status == 'error') {
          toast.error(res.data.message);
        } else {
          toast.error('حدث خطأ ما');
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setuploadloading(false);
      });
  };
  const handleUplaoadImageAfterEdit = () => {
    setuploadloading(true);
    const formData = new FormData();
    formData.append('image', img);
    axios
      .post(BASE_URL + 'upload.php', formData)
      .then((res) => {
        console.log(res.data.message);
        if (res.data.status == 'success') {
          setRowData({ ...rowData, image: res.data.message });
          toast.success('تم الرفع');
        } else if (res.data.status == 'error') {
          toast.error(res.data.message);
        } else {
          toast.error('حدث خطأ ما');
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setuploadloading(false);
      });
  };
  const handleChangetStatus = async () => {
    setChangeLoading(true);
    const data_send = {
      ...rowData,
      access_token: userData.access_token,
      admin_id: userData.id,
    };

    await Axios({
      method: "POST",
      url: "https://samasecuritysystems.com/apis/samaaAdmin/admin/gurantee/update_status.php",
      data: data_send,
    })
      .then((res) => {
        console.log(res);
        if (res.status == 'success') {
          toast.success(res.message);
          getgurantees();
          setShowStatusModal(false);
        } else if (res.status == 'error') {
          toast.error(res.message);
        } else {
          toast.error('حدث خطأ ما');
        }
      })
      .finally(setChangeLoading(false));
  };

  useEffect(() => {
    getgurantees();
  }, []);

  return (
    <div className="h-container con-h">
      <DefaultLayout
        children={
          <div className="childs">
            <div className="breadCrumbsFlex">
              <ContentNav head={"نضمن لك"} />
              <button
                className="btn addbtn btn-primary"
                onClick={() => setOpenAdd(true)}
              >
                <imgs
                  src="https://res.cloudinary.com/duovxefh6/image/upload/v1698247380/insert_mox69q.png"
                  alt=""
                />
                <span>إضافة ضمان</span>
              </button>
            </div>
            <Table headers={headers} body={data} classess={["table-tc"]} />
            <Modal
              open={openAdd}
              toggle={setOpenAdd}
              headerTitle={"إضافة ضمان"}
              children={
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    return !addLoading ? handleAddGurantee() : null;
                  }}
                >
                  <div className="inputField">
                    <label htmlFor="">إسم الضمان</label>
                    <input
                      type="text"
                      onChange={(e) => {
                        setNewGurantee({
                          ...newGurantee,
                          name: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="inputField">
                    <label htmlFor="">وصف الضمان</label>
                    <textarea
                      name=""
                      type="text"
                      onChange={(e) => {
                        setNewGurantee({
                          ...newGurantee,
                          description: e.target.value,
                        });
                      }}
                      id=""
                      cols="30"
                      rows="10"
                    ></textarea>
                  </div>
                  <div className="input_field mb-2">
                    <label htmlFor="">صورة المنتج</label>
                    <input
                      type="file"
                      className="form-control"
                      onChange={(e) => {
                        setImg(e.target.files[0]);
                      }}
                    />
                    <div>
                      {uploadloading ? (
                        <Loader />
                      ) : (
                        <button
                          onClick={() => {
                            handleUplaoadImage();
                          }}
                          style={{ width: '100px' }}
                          className="btn mt-2 btn-success"
                        >
                          رفع
                        </button>
                      )}
                    </div>
                  </div>
                  {addLoading ? (
                    <Loader />
                  ) : (
                    <button
                      disabled={uploadloading}
                      className="btn btn-success d-block m-auto"
                    >
                      إضافة
                    </button>
                  )}
                </form>
              }
            />
            <Modal
              open={openEdit}
              toggle={setOpenEdit}
              headerTitle={"تعديل ضمان"}
              children={
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    return !addLoading ? editgurantee() : null;
                  }}
                >
                  <div className="inputField">
                    <label htmlFor="">إسم الضمان</label>
                    <input
                      type="text"
                      value={rowData.name}
                      onChange={(e) => {
                        setRowData({
                          ...rowData,
                          name: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="inputField">
                    <label htmlFor="">وصف الضمان</label>
                    <textarea
                      name=""
                      type="text"
                      value={rowData.description}
                      onChange={(e) => {
                        setRowData({
                          ...rowData,
                          description: e.target.value,
                        });
                      }}
                      id=""
                      cols="30"
                      rows="10"
                    ></textarea>
                  </div>
                  <div className="input_field mb-2">
                    <label htmlFor="">صورة المنتج</label>
                    <input
                      type="file"
                      className="form-control"
                      onChange={(e) => {
                        setImg(e.target.files[0]);
                      }}
                    />
                    <div>
                      {uploadloading ? (
                        <Loader />
                      ) : (
                        <button
                          onClick={() => {
                            handleUplaoadImageAfterEdit();
                          }}
                          style={{ width: '100px' }}
                          className="btn mt-2 btn-success"
                        >
                          رفع
                        </button>
                      )}
                    </div>
                  </div>
                  {addLoading ? (
                    <Loader />
                  ) : (
                    <button
                      disabled={uploadloading}
                      className="btn btn-success d-block m-auto"
                    >
                      تعديل
                    </button>
                  )}
                </form>
              }
            />
            <Modal
              open={showStatusModal}
              toggle={setShowStatusModal}
              headerTitle={"تغيير حالة الظهور"}
              children={
                <>
                  <p>
                    هل تريد حقا {rowData.hidden == '0' ? 'إخفاء' : 'إظهار'} هذا
                    المنتج
                  </p>
                  <div className="actions align-items-center d-flex gap-2">
                    {changeLoading ? (
                      <Loader />
                    ) : (
                      <button
                        onClick={() => {
                          handleChangetStatus();
                        }}
                        className="btn btn-success"
                      >
                        نعم
                      </button>
                    )}
                    <button
                      onClick={() => {
                        setShowStatusModal(false);
                      }}
                      className="btn btn-danger"
                    >
                      لا
                    </button>
                  </div>
                </>
              }
            />
          </div>
        }
      />
    </div>
  );
}

export default Gurantees;
