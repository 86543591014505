import React, { Fragment } from "react";
import "./style.css";
import { useLocation, useSearchParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import Loader from "../loader";
function Table({ headers, body, classess, children, showPagination = true }) {
  const location = useLocation();
  const [paginatedData, setPaginatedData] = useState([]);
  const [originalData, setOriginalData] = useState(body);
  const [pageTwo, setPageTwo] = useSearchParams(
    { q: 1 },
    { replace: true, state: location?.state }
  );
  const [pagesNumber, setPagesNumber] = useState();

  const [number, setNumber] = useState(10);
  useEffect(() => {
    setOriginalData(body);
  }, [body]);
  useEffect(() => {
    if (
      body &&
      body?.length &&
      Array?.isArray(body) &&
      originalData &&
      originalData?.length &&
      Array.isArray(originalData)
    ) {
      setPagesNumber(Math.ceil(originalData?.length / number));

      setPaginatedData(
        originalData?.filter(
          (item, index) =>
            index >= (parseInt(pageTwo.get("q")) - 1) * number &&
            index <= parseInt(pageTwo.get("q")) * number
        )
      );
    }
  }, [pageTwo, originalData, number, body]);

  useEffect(() => {
    setPageTwo({ q: 1 }, { replace: true, state: location?.state });
  }, [number]);
  const getPageButtons = () => {
    if (pagesNumber <= 5) {
      return Array.from({ length: pagesNumber }, (_, i) => i + 1);
    } else if (parseInt(pageTwo.get("q")) <= 3) {
      return [1, 2, 3, "...", pagesNumber];
    } else if (parseInt(pageTwo.get("q")) > pagesNumber - 2) {
      return [1, "...", pagesNumber - 2, pagesNumber - 1, pagesNumber];
    } else {
      return [
        1,
        "...",
        parseInt(pageTwo.get("q")) - 1,
        parseInt(pageTwo.get("q")),
        parseInt(pageTwo.get("q")) + 1,
        "...",
        pagesNumber,
      ];
    }
  };

  return (
    <>
      <div className={!classess ? "table-t" : classess.join(" ") + " table-t"}>
        <table>
          <thead>
            <tr>
              {headers
                ? headers.map((item, index) => {
                    return <th key={index}>{item.label}</th>;
                  })
                : null}
            </tr>
          </thead>
          <tbody>
            {!body ? (
              <Loader />
            ) : body.length ? (
              paginatedData.map((item, index) => {
                return (
                  <tr key={index}>
                    {headers
                      ? headers.map((b_item, index) => {
                          if (!b_item?.type) {
                            return (
                              <td key={index}>
                                {" "}
                                <span id={item[b_item?.dataIndex]}>
                                  {" "}
                                  {item[b_item?.dataIndex]}{" "}
                                </span>
                              </td>
                            );
                          } else if (b_item?.type == "img") {
                            return (
                              <td key={index}>
                                <img
                                  src={
                                    item["src"]
                                      ? item["src"]
                                      : item[b_item["dataIndex"]]
                                  }
                                />
                              </td>
                            );
                          } else if (b_item?.type == "link") {
                            return (
                              <td key={index}>
                                <a
                                  target="_black"
                                  href={
                                    b_item?.link
                                      ? b_item?.link.startsWith("https") ||
                                        b_item?.link.startsWith("http")
                                        ? b_item?.link
                                        : "https://" + b_item?.link
                                      : item[b_item["dataIndex"]]
                                      ? item[b_item["dataIndex"]].startsWith(
                                          "https"
                                        ) ||
                                        item[b_item["dataIndex"]].startsWith(
                                          "http"
                                        )
                                        ? item[b_item["dataIndex"]]
                                        : "https://" + item[b_item["dataIndex"]]
                                      : null
                                  }
                                >
                                  {b_item["linkName"]
                                    ? b_item["linkName"]
                                    : item[b_item["linkNameIndex"]]
                                    ? item[b_item["linkNameIndex"]]
                                    : null}
                                </a>
                              </td>
                            );
                          } else if (b_item?.type == "action") {
                            return (
                              <td key={index}>
                                {" "}
                                <button
                                  className="btn btn-success"
                                  onClick={() =>
                                    b_item.action
                                      ? b_item.action({ item })
                                      : null
                                  }
                                >
                                  عرض
                                </button>
                              </td>
                            );
                          } else if (b_item?.type == "children") {
                            return (
                              <td key={index}>
                                {b_item?.children({
                                  headers: b_item,
                                  row: item,
                                })}
                              </td>
                            );
                          } else if (b_item?.type == "actions") {
                            return (
                              <td key={index}>
                                {" "}
                                <div className="table_btns">
                                  {b_item?.apperedConditions ? (
                                    <Fragment>
                                      {b_item?.actions
                                        ? b_item?.actions.map(
                                            (bb_item, index) => {
                                              return (
                                                <button
                                                  key={index}
                                                  className={"btn btn-success"}
                                                  onClick={() =>
                                                    bb_item?.action
                                                      ? bb_item?.action({
                                                          item,
                                                        })
                                                      : null
                                                  }
                                                >
                                                  {bb_item?.label}
                                                </button>
                                              );
                                            }
                                          )
                                        : null}
                                    </Fragment>
                                  ) : (
                                    <Fragment>
                                      {b_item?.actions
                                        ? b_item?.actions.map(
                                            (bb_item, index) => {
                                              return (
                                                <button
                                                  key={index}
                                                  className={
                                                    bb_item?.class
                                                      ? "btn " + bb_item?.class
                                                      : "btn btn-success"
                                                  }
                                                  onClick={() => {
                                                    return bb_item?.action
                                                      ? bb_item?.action({
                                                          item,
                                                        })
                                                      : null;
                                                  }}
                                                >
                                                  {bb_item?.label}
                                                </button>
                                              );
                                            }
                                          )
                                        : null}
                                    </Fragment>
                                  )}
                                </div>
                              </td>
                            );
                          }
                        })
                      : null}
                  </tr>
                );
              })
            ) : (
              <tr id="noData">
                <td colSpan={headers.length}> لا توجد بيانات </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {showPagination ? (
        <div className="pagination">
          <div>
            <select
              name="rowNumbers"
              id="rowNumbers"
              onChange={(e) => setNumber(e.target.value)}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="150">150</option>
              <option value="200">200</option>
              <option value="250">250</option>
              <option value="300">300</option>
              <option value="350">350</option>
              <option value="400">400</option>
              <option value="450">450</option>
              <option value="500">500</option>
              <option value="1000">1000</option>
            </select>
          </div>
          {body && body?.length && Array?.isArray(body) ? (
            <p>
              <button
                className="btn"
                onClick={() =>
                  setPageTwo(
                    {
                      q:
                        pageTwo.get("q") > 1
                          ? parseInt(pageTwo.get("q")) - 1
                          : pageTwo.get("q"),
                    },
                    { replace: true, state: location?.state }
                  )
                }
              >
                <img
                  src="https://res.cloudinary.com/duovxefh6/image/upload/v1701861619/next_qp75a5.png"
                  alt=""
                />
              </button>

              {getPageButtons().map((button, index) => (
                <button
                  className={
                    button == parseInt(pageTwo.get("q"))
                      ? "btn btn-primary paginated"
                      : "btn paginated"
                  }
                  key={index}
                  onClick={() =>
                    button == "..."
                      ? null
                      : setPageTwo(
                          {
                            q: button,
                          },
                          { replace: true, state: location?.state }
                        )
                  }
                >
                  {button}
                </button>
              ))}

              <button
                className="btn"
                onClick={() => {
                  setPageTwo(
                    {
                      q:
                        pageTwo.get("q") < pagesNumber
                          ? parseInt(pageTwo.get("q")) + 1
                          : pageTwo.get("q"),
                    },
                    { replace: true, state: location?.state }
                  );
                }}
              >
                <img
                  src="https://res.cloudinary.com/duovxefh6/image/upload/v1701861622/back_vhxuvm.png"
                  alt=""
                />
              </button>
            </p>
          ) : null}
        </div>
      ) : null}
    </>
  );
}

export default Table;
