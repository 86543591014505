import axios from "axios";

const samaaAdmin = localStorage.getItem("samaa_admin");
let user = null;

(function () {
  try {
    user = JSON.parse(samaaAdmin);
  } catch (e) {
    localStorage.removeItem("samaa_admin");
  }
})();

export const UserData = user;

export const Axios = async ({
  method,
  config,
  data,
  url,
  headers,
  timeout = 50000,
  file = false,
}) => {
  const source = axios.CancelToken.source();
  if (file) {
    data?.append("admin_id", user?.id);
    data?.append("access_token", user?.access_token);
  }
  const axiosConfig = {
    method: method,
    url: url,
    data: file
      ? data
      : user
      ? { admin_id: user?.id, access_token: user?.access_token, ...data }
      : data,
    headers: {
      ...headers,
      Authorization: `Bearer ${samaaAdmin}`,
    },
    timeout: timeout,
    cancelToken: source.token,
    ...config,
  };

  try {
    // Request interceptor
    axios.interceptors.request.use((request) => {
      return request;
    });

    const response = await axios(axiosConfig);

    // Response interceptor
    axios.interceptors.response.use((response) => {
      return response;
    });
    if (response.data?.status == "out") {
      localStorage.removeItem("AbdElghanyUser");
      window.location.reload();
    }

    return response.data;
  } catch (error) {
    if (axios.isCancel(error)) {
    } else {
    }

    return error?.message;
  }
};

// Usage example:
