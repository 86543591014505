import { ToastContainer } from "react-toastify";
import "./App.css";
import RouteComponent from "./routes/index";
import "react-toastify/dist/ReactToastify.css";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Login from "./pages/login";
// import { LoggedIn, loggedInData } from "./data/fakeLoginData";

// export let userData = loggedInData;

function App() {
  const navigate = useNavigate();
  let localLoggedIn=localStorage.getItem("samaa_admin");
  let LoggedIn=localLoggedIn&&JSON.parse(localLoggedIn);
  useEffect(() => {
    if (!LoggedIn) {
      navigate("/login");
    }
  }, [LoggedIn]);

  return (
    <div className="App">
      {!LoggedIn && !LoggedIn?.length ? (
        <Routes>
          <Route path="*" element={<Login />} />
        </Routes>
      ) : (
        <RouteComponent />
      )}
      <ToastContainer />
    </div>
  );
}

export default App;
