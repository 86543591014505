export const fakeCredintials = [
  {
    name: "سماء التنميه الأمنيه",
    image: require("../assets/logo/logo.png"),
    email: "samaa",
    password: "7410",
    type: "admin",
  },
];

export let loggedInData = localStorage.getItem("realestateauthenticatedUser")
  ? JSON.parse(localStorage.getItem("realestateauthenticatedUser"))
  : null;

export const LoggedIn = fakeCredintials?.filter(
  (item) => item.email == loggedInData?.email
)?.length;

loggedInData = fakeCredintials?.filter(
  (item) => item.email == loggedInData?.email
)[0];
