export const productsData=[
  {
    product_id:1,
    product_price:500,
    product_image:require("../../assets/images/prod2.png"),
    product_name:'أجهزة التحكم بالأبواب',
    product_description:'تقدم أجهزة التحكم بالأبواب حلاً فعالًا لتأمين المداخل والمخارج. تتيح هذه الأجهزة إمكانية إدارة الوصول بشكل ذكي، حيث يمكن تعيين صلاحيات مختلفة للمستخدمين وتسجيل الحركات بشكل دقيق.',
  },
  {
    product_id:2,
    product_price:700,
    product_image:require("../../assets/images/prod3.png"),
    product_name:'أجهزة التحكم بالأبواب',
    product_description:'تعتبر أجهزة التحكم بالأبواب حلاً مبتكرًا لتوفير أمان إضافي في المؤسسات والمنازل. يمكن تكوين هذه الأجهزة للتحكم في فتح وإغلاق الأبواب بشكل فعال، مع ميزات تحكم متقدمة لضمان الأمان الشامل.',
  },
  {
    product_id:3,
    product_price:700,
    product_image:require("../../assets/images/prod2.png"),
    product_name:'أجهزة التحكم بالأبواب',
    product_description:'تعتبر أجهزة التحكم بالأبواب حلاً مبتكرًا لتوفير أمان إضافي في المؤسسات والمنازل. يمكن تكوين هذه الأجهزة للتحكم في فتح وإغلاق الأبواب بشكل فعال، مع ميزات تحكم متقدمة لضمان الأمان الشامل.',
  },
]
