import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../pages/home";
import Categories from "../pages/categories";
import Products from "../pages/products";
import CategoryProducts from "../pages/CategoryProducts/CategoryProducts";
import Brands from "../pages/Brands/Brands";
import Services from "../pages/services_samaa";
import MessageHistory from "../pages/Message_History";
import OrderHistory from "../pages/Order_history";
import About from "../pages/About/About";
import Features from "../pages/Features/Features";
import TestMonials from "../pages/TestMonials/TestMonials";
import Banners from "../pages/Banners/Banners";
import Clients from "../pages/Clients";
import Gurantees from "../pages/Gurantee/Gurantee";

function RouteComponent() {
  return (
    <Routes>
      <Route path="/">
        <Route path="" element={<Home />} />
        <Route path="cateogryproducts" element={<CategoryProducts />} />
        <Route path="categories" element={<Categories />} />
        <Route path="products" element={<Products />} />
        <Route path="brands" element={<Brands />} />
        <Route path="Services" element={<Services />} />
        <Route path="Message_History" element={<MessageHistory />} />
        <Route path="order_history" element={<OrderHistory />} />
        <Route path="about" element={<About />} />
        <Route path="messages" element={<TestMonials />} />
        <Route path="features" element={<Features />} />
        <Route path="banners" element={<Banners />} />
        <Route path="clients" element={<Clients />} />
        <Route path="gurantee" element={<Gurantees />} />
      </Route>
      <Route path="*" element={<Home />} />
    </Routes>
  );
}

export default RouteComponent;
