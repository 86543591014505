export const brandsData=[
  {
    brand_id:1,
    brand_image:require("../../assets/images/brand1.png"),
  },
  {
    brand_id:2,
    brand_image:require("../../assets/images/brand2.png"),
  },
  {
    brand_id:3,
    brand_image:require("../../assets/images/brand3.png"),
  },
  {
    brand_id:4,
    brand_image:require("../../assets/images/brand4.png"),
  },
  {
    brand_id:5,
    brand_image:require("../../assets/images/brand5.jpg"),
  },
]
