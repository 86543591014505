import React from "react";
import DefaultLayout from "../../layout/defaultlayout";
import ContentNav from "../../datanavcontent";
import "./style.css";

function Home() {
  return (
    <div className="h-container con-h">
      <DefaultLayout
        children={
          <div className="childs">
            <ContentNav head={"الرئيسية"} />
          </div>
        }
      />
    </div>
  );
}

export default Home;
