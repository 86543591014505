import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layout/defaultlayout";
import ContentNav from "../../datanavcontent";
import Table from "../../components/table";
import "./style.css";

import "./style.css";

import Modal from "../../components/modal";
import axios from "axios";
import { Axios } from "../../components/axios";

function MessageHistory() {
  const [rowData,setRowData]=useState({});

  let localData=localStorage.getItem("samaa_admin");
  let userData=localData&&JSON.parse(localData);
  const [pageLoading,setPageLoading]=useState(false)
  const [data, setData] = useState();
  const [showMessage, setShowMessage] = useState(false);
  // const select_how_i_work = async () => {
  //   const select = await axios.get(
  //     "https://camp-coding.tech/alaqra_profile/admin/form/select_form_msgs.php"
  //   );
  //   setData(select?.data?.message);
  // };
  const getMessages = () => {
    // setData(productsData);
    setPageLoading(true)
    const data_send={
      "access_token":userData.access_token,
    "id":userData.id,
    "user_type":"admin"
    }
    Axios({
      method: 'POST',
      url: "https://samasecuritysystems.com/apis/samaaAdmin/admin/messages/index.php",
      data:data_send
    })
    .then((res) => {
        console.log(res)
        if (res.status == 'success') {
          setData(res.message);
        } else if(res.status=='error'){
            localStorage.removeItem('samaa_admin');
            window.location.reload();
        }
    })
    .finally(() => {
      setPageLoading(false);
    });
  };
  useEffect(() => {
    getMessages();
  }, []);

  const headers = [
    {
      label: "رقم الرسالة",
      dataIndex: "id",
    },
    {
      label: "إسم الشخص",
      dataIndex: "name",
    },
    {
      label: "البريد الالكتروني",
      dataIndex: "email",
    },
    {
      label: "الرساله",
      dataIndex: "message",
    },

    {
      label: "أوامر",
      type: 'children',
      children: ({ row, headers }) => {
        return (
          <div style={{display:'flex',alignItems:'center',justifyContent:'center',gap:'4px',flexWrap:'wrap'}}>
            <button className="btn btn-success" onClick={()=>{
              setRowData(row)
              setShowMessage(true)
            }}>عرض الرساله</button>
          </div>
        );
      },
    },
  ];
  return (
    <div className="h-container con-h">
      <DefaultLayout
        children={
          <div className="childs">
            <ContentNav head={"سجل الرسائل"} />
            <Table headers={headers} body={data} classess={["table-tc"]} />
            <Modal
              open={showMessage}
              toggle={setShowMessage}
              headerTitle={"الرسالة رقم " + rowData.id}
              children={
                <div className="message_details">
                  {" "}
                  <p>
                    <span>اسم العميل:</span>
                    <pre>{rowData?.name}</pre>
                  </p>
                  <p>
                    <span>البريد الالكتروني :</span>
                    <pre>
                      <span>{rowData.email}</span>{" "}
                      <a
                        href={"mailto:" + rowData.email}
                        className="btn"
                      ></a>
                    </pre>
                  </p>
                  <p className="message">
                    <span>نص الرسالة:</span>
                    <pre>{rowData.message}</pre>
                  </p>
                </div>
              }
            />
          </div>
        }
      />
    </div>
  );
}

export default MessageHistory;
