import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layout/defaultlayout";
import ContentNav from "../../datanavcontent";
import Table from "../../components/table";
import "./style.css";
import Modal from "../../components/modal";
import axios from "axios";
import { Axios } from "../../components/axios";
import { API_ROUTES, BASE_URL } from "../../components/axios/BASE_URL";
import ImageInput from "../../components/imageInput/ImageInput";
import { toast } from "react-toastify";
import Loader from "../../components/loader";
import { useLocation, useNavigate } from "react-router-dom";
import { productsData } from "./data";
import { edit, hidde, show } from "../../navbar/sidenav/svgImages";
import ImagesInput from "../../components/imageInput/ImageInputMulti";
import { Attachment } from "../../assets/svgIcons";

function Products() {
  let localData = localStorage.getItem("samaa_admin");
  let userData = localData && JSON.parse(localData);
  // console.log(userData)
  const location = useLocation();
  const state = location.state;
  // console.log(state)
  // const [categories,setCategories]=useState([])
  const [addLoading, setAddLoading] = useState(false);
  const [uploadloading, setuploadloading] = useState(false);
  const [changeLoading, setChangeLoading] = useState(false);
  const [rowData, setRowData] = useState({});
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [data, setData] = useState();
  const [pdf, setPdf] = useState();
  const [openAdd, setOpenAdd] = useState();
  const [openEdit, setOpenEdit] = useState();
  const [images, setImages] = useState({});
  const [img, setImg] = useState(null);
  const [newPdfData, setNewPdfData] = useState({});
  const [showAttachments, setShowAttachments] = useState(null);
  const [categories, setCategories] = useState([]);
  const [newImage, setNewImage] = useState(null);
  const [newData, setNewData] = useState({
    name: "",
    description: "",
    images: [],
    category_id: "",
    diems: [{ id: 0, label: "", value: "" }],
    videos: [{ id: 0, link: "" }],
    video_link: "",
  });
  const [loader, setLoader] = useState(null);

  const headers = [
    {
      label: "رقم المنتج",
      dataIndex: "id",
    },
    // {
    //   label: "صورة المنتج",
    //   dataIndex: "image",
    //   type: 'children',
    //   children: ({ row, headers }) => {
    //     return (
    //       <img
    //         style={{ width: '100px', maxHeight: '100px' }}
    //         src={row.image}
    //         alt=""
    //       />
    //     );
    //   },
    // },
    {
      label: "إسم المنتج",
      dataIndex: "name",
    },
    {
      label: "وصف المنتج",
      dataIndex: "description",
    },
    {
      label: "رابط الفيديو",
      type: "children",
      children: ({ row, header }) => {
        return (
          <>
            {" "}
            {row?.video_link && row?.video_link?.length ? (
              <iframe
                width="320"
                height="320"
                src={row?.video_link?.replace("watch?v=", "embed/")}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            ) : null}
          </>
        );
      },
    },
    {
      label: "الأدوات",
      dataIndex: "",
      type: "children",
      children: ({ row, header }) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "4px",
              flexWrap: "wrap",
            }}
          >
            <span
              onClick={() => {
                setRowData(row);
                setOpenEdit(true);
              }}
              style={{ color: "green", cursor: "pointer" }}
            >
              {edit}
            </span>
            {row.hidden == "0" ? (
              <span
                onClick={() => {
                  setRowData(row);
                  setShowStatusModal(true);
                }}
                style={{ color: "green", cursor: "pointer" }}
              >
                {show}
              </span>
            ) : (
              <span
                onClick={() => {
                  setRowData(row);
                  setShowStatusModal(true);
                }}
                style={{ color: "red", cursor: "pointer" }}
              >
                {hidde}
              </span>
            )}
            <span
              style={{ color: "green", cursor: "pointer" }}
              onClick={() => setShowAttachments(row)}
            >
              {Attachment}
            </span>
          </div>
        );
      },
    },
  ];

  const getCategories = () => {
    setPageLoading(true);
    const data_send = {
      access_token: userData.access_token,
      id: userData.id,
    };
    Axios({
      method: "POST",
      url: "https://samasecuritysystems.com/apis/samaaAdmin/admin/category/index.php",
      data: data_send,
    }).then((res) => {
      if (res.status == "success") {
        setCategories(res.data);
        setNewData({ ...newData, category_id: res.data[0].id });
      } else if (res?.data?.status == "error") {
        localStorage.removeItem("samaa_admin");
        window.location.reload();
      }
    });
  };
  // Get Categories
  const getProducts = () => {
    // setData(productsData);
    setPageLoading(true);
    const data_send = {
      access_token: userData.access_token,
      admin_id: userData.id,
      user_type: "admin",
    };
    Axios({
      method: "POST",
      url: "https://samasecuritysystems.com/apis/samaaAdmin/admin/product/index.php",
      data: data_send,
    })
      .then((res) => {
        console.log(res);
        if (res?.status == "success") {
          console.log(res?.message);
          if (res?.message?.videos && res?.message?.videos?.length)
            res?.message?.videos?.map((item, index) => {
              item.video_id = item?.id;
              item.id = index;
            });

          if (res?.message?.dims && res?.message?.dims?.length)
            res?.message?.dims?.map((item, index) => {
              item.diem_id = item?.id;
              item.id = index;
            });
          setData(res.message);
        } else if (res?.data?.status == "error") {
          localStorage.removeItem("samaa_admin");
          window.location.reload();
        }
      })
      .finally(() => {
        setPageLoading(false);
      });
  };
  const addProducts = async () => {
    setLoader(true);
    setAddLoading(true);

    const data_send = {
      ...newData,
      access_token: userData.access_token,
      admin_id: userData.id,
      image: newData?.images?.join("**camp**"),
      pdf: pdf,
      product_dimensions: newData?.diems
        ?.map((item) => item?.label + "*camp*" + item?.value)
        .join("**camp**"),
      video_links: newData?.videos?.map((item) => item?.link).join("**camp**"),
    };
    delete data_send?.diems;
    delete data_send?.images;
    delete data_send?.videos;
    await Axios({
      method: "POST",
      url: "https://samasecuritysystems.com/apis/samaaAdmin/admin/product/create_product.php",
      data: data_send,
    })
      .then((res) => {
        console.log(res);
        if (res.status == "success") {
          getProducts();
          setOpenAdd(false);
          setNewData({
            diems: [{ id: 0, label: "", value: "" }],
            videos: [{ id: 0, link: "" }],
          });
          setPdf(null);

          setImages({});
          toast.success("تمت الإضافة بنجاح");
        } else {
          toast.error(res?.message);
        }
      })
      .finally(() => {
        setLoader(false);
        setAddLoading(false);
      });
  };

  const handleUplaoadImageafterEdit = () => {
    setuploadloading(true);
    const formData = new FormData();
    formData.append("image", img);
    axios
      .post(BASE_URL + "upload.php", formData)
      .then((res) => {
        console.log(res.data.message);
        if (res.data.status == "success") {
          setRowData({ ...rowData, image: res.data.message });
          toast.success("تم الرفع");
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setuploadloading(false);
      });
  };
  const handleUplaoadImage = () => {
    if (!images || !images.length) return;
    images?.forEach((value, index, array) => {
      setuploadloading(true);
      const formData = new FormData();
      formData.append("image", value);
      axios
        .post(BASE_URL + "upload.php", formData)
        .then((res) => {
          // console.log(res)
          console.log(res.data.message);
          if (res.data.status == "success") {
            setNewData({
              ...newData,
              images: [...newData?.images, res.data.message],
            });
            toast.success("تم الرفع");
          } else if (res.data.status == "error") {
            toast.error(res.data.message);
          } else {
            toast.error("حدث خطأ ما");
          }
        })
        .catch((e) => console.log(e))
        .finally(() => {
          setuploadloading(false);
        });
    });
  };
  const handleUplaoadFile = () => {
    // if (!pdf) return;

    const formData = new FormData();
    formData.append("file_attachment", pdf);
    axios
      .post(BASE_URL + "uploud_pdf.php", formData)
      .then((res) => {
        // console.log(res)
        console.log(res.data.message);
        if (res.data.status == "success") {
          setNewData({
            ...newData,
            product_pdf: res.data.message,
          });
          toast.success("تم الرفع");
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => { });
  };
  const editProduct = async () => {
    setLoader(true);
    setAddLoading(true);
    const data_send = {
      ...rowData,
      access_token: userData.access_token,
      admin_id: userData.id,
      id: rowData.id,
      product_dimensions: rowData?.dims
        ?.map((item) => item?.label + "*camp*" + item?.value)
        .join("**camp**"),
      video_links: rowData?.videos
        ?.map((item) => item?.video_link)
        .join("**camp**"),
    };
    delete data_send?.product_image;
    delete data_send?.category_data;
    delete data_send?.product_image;
    delete data_send?.pdfs;
    delete data_send?.dims;
    delete data_send?.videos;

    await Axios({
      method: "POST",
      url: "https://samasecuritysystems.com/apis/samaaAdmin/admin/product/update_product.php",
      data: data_send,
    })
      .then((res) => {
        console.log(res);
        if (res.status == "success") {
          getProducts();
          setOpenEdit(false);

          toast.success(res.message);
        } else {
          toast.error(res?.message);
        }
      })
      .finally(() => {
        setLoader(false);
        setAddLoading(false);
      });
  };

  const handleChangetStatus = async () => {
    setChangeLoading(true);
    const data_send = {
      ...rowData,
      access_token: userData.access_token,
      admin_id: userData.id,
    };
    const formData = new FormData();
    formData.append("access_token", userData.access_token);
    formData.append("admin_id", userData.id);
    formData.append("id", rowData.id);
    // console.log(data_send)
    await Axios({
      method: "POST",
      url: "https://samasecuritysystems.com/apis/samaaAdmin/admin/product/update_status.php",
      data: data_send,
    })
      .then((res) => {
        // console.log(res)
        if (res.status == "success") {
          toast.success(res.message);
          getProducts();
          setShowStatusModal(false);
        } else if (res.status == "error") {
          toast.error(res.message);
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .finally(setChangeLoading(false));
  };

  const deleteImage = async (id) => {
    const deleteImg = await Axios({
      method: "POST",
      url: "https://samasecuritysystems.com/apis/samaaAdmin/admin/product/delete_image.php",
      data: {
        image_id: id,
      },
    });
    if (deleteImg?.status == "success") {
      setRowData({
        ...rowData,
        product_image: rowData?.product_image?.filter(
          (item) => item?.image_id != id
        ),
      });
    }
    toast.dark(deleteImg?.message);
  };
  const deletePdf = async (id) => {
    const deleteImg = await Axios({
      method: "POST",
      url: "https://samasecuritysystems.com/apis/samaaAdmin/admin/product/delete_pdf.php",
      data: {
        pdf_id: id,
      },
    });
    if (deleteImg?.status == "success") {
      setShowAttachments(null);
      getProducts();
    }
    toast.dark(deleteImg?.message);
  };

  const uploadImage = async (id) => {
    const formData = new FormData();
    formData.append("image", newImage);
    const newDataImage = await axios.post(BASE_URL + "upload.php", formData);
    let imageEdit = newDataImage.data.message;
    if (newDataImage?.data?.status == "success") {
      const addImg = await Axios({
        method: "POST",
        url: "https://samasecuritysystems.com/apis/samaaAdmin/admin/product/add_image.php",
        data: {
          image_id: id,
          product_id: rowData?.id,
          image_url: imageEdit,
        },
      });
      if (addImg?.status == "success") {
        setNewImage(null);
        getProducts();
        setRowData({
          ...rowData,
          product_image: [
            ...rowData?.product_image,
            { image_id: addImg?.message, image: newDataImage.data.message },
          ],
        });
        toast.dark("تمت الاضافة بنجاح");
      } else {
        toast.dark(addImg?.message);
      }
    } else {
      toast.error("حدث خطأ في رفع الصورة رجاء المحاولة مرة أخرى");
    }
  };
  useEffect(() => {
    console.log(newData);
  }, [newData]);
  const uploadNewPdf = async (id) => {
    const formData = new FormData();
    formData.append("file_attachment", newPdfData?.file);
    const newDataImage = await axios.post(
      BASE_URL + "uploud_pdf.php",
      formData
    );
    newPdfData.pdf_url = newDataImage.data.message;
    if (newDataImage?.data?.status == "success") {
      const addImg = await Axios({
        method: "POST",
        url: "https://samasecuritysystems.com/apis/samaaAdmin/admin/product/add_pdf.php",
        data: {
          ...newPdfData,
          product_id: showAttachments.id,
        },
      });
      if (addImg?.status == "success") {
        setNewImage(null);
        getProducts();
        setShowAttachments(null);
        setNewPdfData({});
        toast.dark("تمت الاضافة بنجاح");
      } else {
        toast.dark(addImg?.message);
      }
    } else {
      toast.error("حدث خطأ في رفع الصورة رجاء المحاولة مرة أخرى");
    }
  };

  useEffect(() => {
    getProducts();
    getCategories();
  }, []);

  return (
    <div className="h-container con-h">
      <DefaultLayout
        children={
          <div className="childs">
            <div className="breadCrumbsFlex">
              <ContentNav head={"المنتجات"} />
              <button
                className="btn addbtn btn-primary"
                onClick={() => setOpenAdd(true)}
              >
                <img
                  src="https://res.cloudinary.com/duovxefh6/image/upload/v1698247380/insert_mox69q.png"
                  alt=""
                />
                <span>إضافة منتج</span>
              </button>
            </div>
            <Table headers={headers} body={data} classess={["table-tc"]} />
            <Modal
              open={openAdd}
              toggle={setOpenAdd}
              headerTitle={"إضافة منتج"}
              children={
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    return !addLoading ? addProducts() : null;
                  }}
                >
                  <div className="inputField">
                    <label htmlFor="">إسم الفئه</label>
                    <select
                      onChange={(e) => {
                        setNewData({ ...newData, category_id: e.target.value });
                      }}
                      value={newData.category_id}
                      className="form-control"
                    >
                      {categories?.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="inputField">
                    <label htmlFor="">إسم المنتج</label>
                    <input
                      type="text"
                      onChange={(e) => {
                        setNewData({ ...newData, name: e.target.value });
                      }}
                    />
                  </div>
                  <div className="inputField">
                    <div className="rowDiv">
                      <label htmlFor="">أبعاد المنتج</label>
                      <span
                        className="addNew btn btn-success"
                        onClick={() =>
                          setNewData({
                            ...newData,
                            diems: [
                              ...newData?.diems,
                              {
                                id:
                                  newData?.diems[newData?.diems?.length - 1]
                                    ?.id + 1,
                                label: "",
                                value: "",
                              },
                            ],
                          })
                        }
                      >
                        +
                      </span>
                    </div>{" "}
                    {newData?.diems?.map((item, index) => {
                      return (
                        <div className="diems" key={index}>
                          {" "}
                          <div className="diem">
                            <label>الاسم</label>
                            <input
                              value={newData?.diems[index]?.label}
                              onChange={(e) => {
                                const updatedDiems = [...newData.diems];
                                updatedDiems[index] = {
                                  ...updatedDiems[index],
                                  label: e.target.value,
                                };
                                setNewData({ ...newData, diems: updatedDiems });
                              }}
                            />
                          </div>
                          <div className="diem">
                            <label>القيمة</label>
                            <input
                              value={newData?.diems[index]?.value}
                              onChange={(e) => {
                                const updatedDiems = [...newData.diems];
                                updatedDiems[index] = {
                                  ...updatedDiems[index],
                                  value: e.target.value,
                                };
                                setNewData({ ...newData, diems: updatedDiems });
                              }}
                            />
                          </div>
                          <span
                            className="addNew btn btn-danger"
                            onClick={() => {
                              if (index !== 0) {
                                const updatedDiems = newData.diems
                                  ?.filter((de_item) => de_item.id !== index)
                                  ?.map((de_item, i) => ({ ...de_item, id: i }));
                                setNewData({ ...newData, diems: updatedDiems });
                              }
                            }}
                          >
                            -
                          </span>
                        </div>
                      );
                    })}
                  </div>
                  <div className="inputField">
                    <label htmlFor="">وصف المنتج</label>
                    <textarea
                      type="text"
                      onChange={(e) => {
                        setNewData({ ...newData, description: e.target.value });
                      }}
                    ></textarea>
                  </div>
                  <div className="inputField">
                    <div className="rowDiv">
                      <label htmlFor="">فيديوهات </label>
                      <span
                        className="addNew btn btn-success"
                        onClick={() =>
                          setNewData({
                            ...newData,
                            videos: [
                              ...newData?.videos,
                              {
                                id:
                                  newData?.videos[newData?.videos?.length - 1]
                                    ?.id + 1,
                                link: "",
                              },
                            ],
                          })
                        }
                      >
                        +
                      </span>
                    </div>{" "}
                    {newData?.videos?.map((item, index) => {
                      return (
                        <div className="diems" key={index}>
                          {" "}
                          <div className="diem">
                            <label>رابط الفيديو</label>
                            <input
                              value={newData?.videos[index]?.link}
                              onChange={(e) => {
                                const updatedDiems = [...newData.videos];
                                updatedDiems[index] = {
                                  ...updatedDiems[index],
                                  link: e.target.value,
                                };
                                setNewData({
                                  ...newData,
                                  videos: updatedDiems,
                                });
                              }}
                            />
                          </div>
                          <span
                            className="addNew btn btn-danger"
                            onClick={() => {
                              if (index !== 0) {
                                const updatedDiems = newData.videos
                                  ?.filter((de_item) => de_item.id !== index)
                                  ?.map((de_item, i) => ({ ...de_item, id: i }));
                                setNewData({
                                  ...newData,
                                  videos: updatedDiems,
                                });
                              }
                            }}
                          >
                            -
                          </span>
                        </div>
                      );
                    })}
                  </div>
                  <div className="input_field mb-2">
                    <label htmlFor="">صور المنتج</label>
                    <ImagesInput
                      values={images}
                      getImages={setImages}
                      id={"produnct_images"}
                      name={"produnct_images"}
                    />

                    <div>
                      {uploadloading ? (
                        <Loader />
                      ) : (
                        <span
                          onClick={() => {
                            handleUplaoadImage();
                          }}
                          style={{ width: "100px" }}
                          className="btn mt-2 btn-success"
                        >
                          رفع الصور
                        </span>
                      )}
                    </div>
                  </div>
                  {!addLoading ? (
                    <button className="btn btn-success"> إضافة</button>
                  ) : (
                    <Loader />
                  )}
                </form>
              }
            />

            <Modal
              open={openEdit}
              toggle={setOpenEdit}
              headerTitle={"تعديل منتج"}
              children={
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    return !loader ? editProduct() : null;
                  }}
                >
                  <div className="inputField">
                    <label htmlFor="">إسم المنتج</label>
                    <input
                      type="text"
                      value={rowData.name}
                      onChange={(e) => {
                        setRowData({
                          ...rowData,
                          name: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="inputField">
                    <label htmlFor="">إسم الفئه</label>
                    <select
                      onChange={(e) => {
                        setRowData({ ...newData, category_id: e.target.value });
                      }}
                      value={rowData.category_id}
                      className="form-control"
                    >
                      {categories?.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="inputField">
                    <div className="rowDiv">
                      <label htmlFor="">أبعاد المنتج</label>
                      <span
                        className="addNew btn btn-success"
                        onClick={() =>
                          setRowData({
                            ...rowData,
                            dims: [
                              ...rowData?.dims,
                              {
                                id:
                                  rowData?.dims[rowData?.dims?.length - 1]?.id +
                                  1,
                                label: "",
                                value: "",
                              },
                            ],
                          })
                        }
                      >
                        +
                      </span>
                    </div>{" "}
                    {rowData?.dims?.map((item, index) => {
                      return (
                        <div className="diems" key={index}>
                          {" "}
                          <div className="diem">
                            <label>الاسم</label>
                            <input
                              value={rowData?.dims[index]?.label}
                              onChange={(e) => {
                                const updatedDiems = [...rowData.dims];
                                updatedDiems[index] = {
                                  ...updatedDiems[index],
                                  label: e.target.value,
                                };
                                setRowData({ ...rowData, dims: updatedDiems });
                              }}
                            />
                          </div>
                          <div className="diem">
                            <label>القيمة</label>
                            <input
                              value={rowData?.dims[index]?.value}
                              onChange={(e) => {
                                const updatedDiems = [...rowData.dims];
                                updatedDiems[index] = {
                                  ...updatedDiems[index],
                                  value: e.target.value,
                                };
                                setRowData({ ...rowData, dims: updatedDiems });
                              }}
                            />
                          </div>
                          <span
                            className="addNew btn btn-danger"
                            onClick={() => {
                              if (index !== 0) {
                                const updatedDiems = rowData.dims
                                  ?.filter((de_item) => de_item.id !== index)
                                  ?.map((de_item, i) => ({ ...de_item, id: i }));
                                setRowData({ ...rowData, dims: updatedDiems });
                              }
                            }}
                          >
                            -
                          </span>
                        </div>
                      );
                    })}
                  </div>
                  <div className="inputField">
                    <label htmlFor="">وصف المنتج</label>
                    <textarea
                      type="text"
                      value={rowData.description}
                      onChange={(e) => {
                        setRowData({
                          ...rowData,
                          description: e.target.value,
                        });
                      }}
                    ></textarea>
                  </div>

                  <div className="inputField">
                    <div className="rowDiv">
                      <label htmlFor="">فيديوهات </label>
                      <span
                        className="addNew btn btn-success"
                        onClick={() =>
                          setRowData({
                            ...rowData,
                            videos: [
                              ...rowData?.videos,
                              {
                                id:
                                  rowData?.videos[rowData?.videos?.length - 1]
                                    ?.id + 1,
                                video_link: "",
                              },
                            ],
                          })
                        }
                      >
                        +
                      </span>
                    </div>{" "}
                    {rowData?.videos?.map((item, index) => {
                      return (
                        <div className="diems" key={index}>
                          {" "}
                          <div className="diem">
                            <label>رابط الفيديو</label>
                            <input
                              value={rowData?.videos[index]?.video_link}
                              onChange={(e) => {
                                const updatedDiems = [...rowData.videos];
                                updatedDiems[index] = {
                                  ...updatedDiems[index],
                                  video_link: e.target.value,
                                };
                                setRowData({
                                  ...rowData,
                                  videos: updatedDiems,
                                });
                              }}
                            />
                          </div>
                          <span
                            className="addNew btn btn-danger"
                            onClick={() => {
                              if (index !== 0) {
                                const updatedDiems = newData.videos
                                  ?.filter((de_item) => de_item.id !== index)
                                  ?.map((de_item, i) => ({ ...de_item, id: i }));
                                setRowData({
                                  ...rowData,
                                  videos: updatedDiems,
                                });
                              }
                            }}
                          >
                            -
                          </span>
                        </div>
                      );
                    })}
                  </div>

                  <div className="input_fields">
                    {rowData?.product_image && rowData?.product_image?.length
                      ? rowData?.product_image?.map((item, index) => {
                        return (
                          <div className="imageViewer" key={index}>
                            <img src={item?.image} alt="images" />
                            <span
                              className="btn btn-danger"
                              onClick={() => {
                                deleteImage(item?.image_id);
                              }}
                            >
                              <img
                                src="https://res.cloudinary.com/duovxefh6/image/upload/v1701864460/delete_1_txgok9.png"
                                alt=""
                              />
                            </span>
                          </div>
                        );
                      })
                      : null}
                  </div>
                  <div className="input_field mb-2 row-gap-md-0">
                    <label htmlFor="">صورة المنتج</label>

                    <ImageInput
                      getImage={setNewImage}
                      value={newImage}
                      id="newImage"
                      name={"newImage"}
                    />
                    <div>
                      {uploadloading ? (
                        <Loader />
                      ) : (
                        <span
                          onClick={() => {
                            uploadImage();
                          }}
                          style={{ width: "100px" }}
                          className="btn mt-2 btn-success"
                        >
                          إضافة الصورة
                        </span>
                      )}
                    </div>
                  </div>
                  {!loader ? (
                    <button className="btn btn-success"> تعديل</button>
                  ) : (
                    <Loader />
                  )}
                </form>
              }
            />
            <Modal
              open={showStatusModal}
              toggle={setShowStatusModal}
              headerTitle={"تغيير حالة الظهور"}
              children={
                <>
                  <p>
                    هل تريد حقا {rowData.hidden == "0" ? "إخفاء" : "إظهار"} هذا
                    المنتج
                  </p>
                  <div className="actions align-items-center d-flex gap-2">
                    {changeLoading ? (
                      <Loader />
                    ) : (
                      <button
                        onClick={() => {
                          handleChangetStatus();
                        }}
                        className="btn btn-success"
                      >
                        نعم
                      </button>
                    )}
                    <button
                      onClick={() => {
                        setShowStatusModal(false);
                      }}
                      className="btn btn-danger"
                    >
                      لا
                    </button>
                  </div>
                </>
              }
            />

            <Modal
              open={showAttachments}
              toggle={setShowAttachments}
              headerTitle={"المرفقات الخاصة بالمنتج"}
            >
              <div className="newPdfs">
                <h4>ملفات قديمة</h4>
                {showAttachments?.pdfs
                  ? showAttachments?.pdfs?.map((item) => (
                    <div>
                      <div className="inputField">{item?.pdf_title}</div>
                      <div className="inputField">
                        <button
                          className="btn btn-success"
                          onClick={() =>
                            window?.open(item?.pdf_url, "_blanck")
                          }
                        >
                          فتح الملف
                        </button>
                      </div>
                      <button
                        className="btn btn-danger"
                        onClick={() => {
                          deletePdf(item?.pdf_id);
                        }}
                      >
                        حذف
                      </button>
                    </div>
                  ))
                  : null}
              </div>
              <div className="newPdfs">
                <h4>إضافة ملف جديد</h4>
                <div>
                  <div className="inputField">
                    <label htmlFor="">اسم الملف</label>
                    <input
                      type="text"
                      value={newPdfData?.pdf_title}
                      onChange={(e) => {
                        setNewPdfData({
                          ...newPdfData,
                          pdf_title: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="inputField">
                    <label htmlFor="">الملف</label>
                    <input
                      type="file"
                      onChange={(e) => {
                        setNewPdfData({
                          ...newPdfData,
                          file: e.target.files[0],
                        });
                      }}
                    />
                  </div>
                </div>
                <button
                  className="btn btn-success"
                  onClick={() => {
                    uploadNewPdf();
                  }}
                >
                  إضافة ملف جديد
                </button>
              </div>
            </Modal>
          </div>
        }
      />
    </div>
  );
}

export default Products;
