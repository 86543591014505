import {
  Services,
  cats,
  main,
  products,
  brands,
  orders,
  messages,
  opinions,
  about,
  features,
} from "./svgImages";

export const SideNavData = [
  {
    path: "/",
    label: "الرئيسية",
    icon: main,
  },
  {
    path: "/categories",
    label: "الفئات",
    icon: cats,
  },
  {
    path: "/products",
    label: "المنتجات",
    icon: products,
  },
  {
    path: "/brands",
    label: "الماركات",
    icon: brands,
  },
  {
    path: "/Services",
    label: "الخدمات",
    icon: Services,
  },
  {
    path: "/Message_History",
    label: "الرسائل",
    icon: messages,
  },
  {
    path: "/order_history",
    label: "الطلبات",
    icon: orders,
  },
  {
    path: "/features",
    label: "المميزات",
    icon: features,
  },
  {
    path: "/clients",
    label: "العملاء",
    icon: opinions,
  },
  {
    path: "/messages",
    label: "الأراء",
    icon: opinions,
  },
  {
    path: "/gurantee",
    label: "نضمن لك",
    icon: opinions,
  },
  {
    path: "/banners",
    label: "البانرات",
    icon: opinions,
  },
  {
    path: "/about",
    label: "عن الشركه",
    icon: about,
  },

  // order_history
];
