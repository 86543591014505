import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layout/defaultlayout";
import ContentNav from "../../datanavcontent";
import Table from "../../components/table";
import "./style.css";

import "./style.css";
import Modal from "../../components/modal";
import axios from "axios";
import { Axios } from "../../components/axios";
import {
  API_ROUTES,
  BASES_ROUTES,
  BASE_URL,
} from "../../components/axios/BASE_URL";
import ImageInput from "../../components/imageInput/ImageInput";
import { toast } from "react-toastify";
import Loader from "../../components/loader";
import { categoriesData } from "./data";
import { useNavigate } from "react-router-dom";
import { edit, hidde, show } from "../../navbar/sidenav/svgImages";

function Categories() {
  const navigate = useNavigate();
  const [uploadloading, setuploadloading] = useState(false);
  const [changeLoading, setChangeLoading] = useState(false);
  const [rowData, setRowData] = useState({});
  const [data, setData] = useState();
  const [openAdd, setOpenAdd] = useState();
  const [openEdit, setOpenEdit] = useState();
  const [categoryData, setCategoryData] = useState({
    name: '',
    description: '',
    image: '',
  });
  const [img, setImg] = useState(null);
  const [loader, setLoader] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  let localData = localStorage.getItem("samaa_admin");
  let userData = localData && JSON.parse(localData);
  const headers = [
    {
      label: "رقم الفئة",
      dataIndex: "id",
    },
    {
      label: "عنوان الفئة",
      dataIndex: "name",
      type: 'children',
      children: ({ row, header }) => {
        return (
          <p
            onClick={() => {
              navigate("/cateogryproducts", { state: { category: row } });
            }}
            style={{ color: 'blue', cursor: 'pointer', textAlign: 'center' }}
          >
            {row.name}
          </p>
        );
      },
    },
    {
      label: "وصف الفئة",
      dataIndex: "description",
      type: 'children',
      children: ({ row, header }) => {
        return (
          <p
            onClick={() => {
              navigate("/cateogryproducts", { state: { category: row } });
            }}
            style={{ color: 'blue', cursor: 'pointer', textAlign: 'center' }}
          >
            {row.description}
          </p>
        );
      },
    },
    {
      label: "صورة الفئة",
      dataIndex: "image",
      type: "children",
      children: ({ row }) => {
        return (
          <img
            style={{ width: '100px' }}
            src={row?.image}
            alt=""
            onClick={() => {
              navigate("/cateogryproducts", { state: { category: row } });
            }}
          />
        );
      },
    },
    {
      label: "الأدوات",
      dataIndex: '',
      type: 'children',
      children: ({ row, header }) => {
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '4px',
              flexWrap: 'wrap',
            }}
          >
            <span
              onClick={() => {
                setRowData(row);
                setOpenEdit(true);
              }}
              style={{ color: 'green', cursor: 'pointer' }}
            >
              {edit}
            </span>
            {row.hidden == '0' ? (
              <span
                onClick={() => {
                  setRowData(row);
                  setShowStatusModal(true);
                }}
                style={{ color: 'green', cursor: 'pointer' }}
              >
                {show}
              </span>
            ) : (
              <span
                onClick={() => {
                  setRowData(row);
                  setShowStatusModal(true);
                }}
                style={{ color: 'red', cursor: 'pointer' }}
              >
                {hidde}
              </span>
            )}
          </div>
        );
      },
    },
  ];
  const getCategories = () => {
    setPageLoading(true);
    const data_send = {
      access_token: userData.access_token,
      id: userData.id,
    };
    Axios({
      method: 'POST',
      url: "https://samasecuritysystems.com/apis/samaaAdmin/admin/category/index.php",
      data: data_send,
    })
      .then((res) => {
        console.log(res);
        if (res.status == 'success') {
          setData(res.data);
        } else if (res.data.status == 'error') {
          localStorage.removeItem('samaa_admin');
          window.location.reload();
        }
      })
      .finally(() => {
        setPageLoading(false);
      });
  };

  const addCategories = async () => {
    setLoader(true);
    const data_send = {
      ...categoryData,
      access_token: userData.access_token,
      admin_id: userData.id,
    };
    // const formData=new FormData();
    // formData.append('image',img);
    // formData.append('access_token',userData.access_token);
    // formData.append('admin_id',userData.id);
    // formData.append('name',categoryData.name);
    // formData.append('description',categoryData.description);
    console.log(data_send);
    await Axios({
      method: "POST",
      url: "https://samasecuritysystems.com/apis/samaaAdmin/admin/category/add_category.php",
      data: data_send,
    })
      .then((res) => {
        console.log(res);
        if (res.status == "success") {
          getCategories();
          setOpenAdd(false);
          toast.success("تمت الإضافة بنجاح");
        } else {
          toast.error(res?.message);
        }
      })
      .finally(setLoader(false));
  };
  const handleChangetStatus = async () => {
    setChangeLoading(true);
    const data_send = {
      ...rowData,
      access_token: userData.access_token,
      admin_id: userData.id,
    };
    const formData = new FormData();
    formData.append('access_token', userData.access_token);
    formData.append('admin_id', userData.id);
    formData.append('id', rowData.id);
    console.log(data_send);
    await Axios({
      method: "POST",
      url: "https://samasecuritysystems.com/apis/samaaAdmin/admin/category/update_status.php",
      data: data_send,
    })
      .then((res) => {
        console.log(res);
        if (res.status == 'success') {
          toast.success(res.message);
          getCategories();
          setShowStatusModal(false);
        } else if (res.status == 'error') {
          toast.error(res.message);
        } else {
          toast.error('حدث خطأ ما');
        }
      })
      .finally(setChangeLoading(false));
  };

  const editCategory = async () => {
    setLoader(true);
    const data_send = {
      ...rowData,

      access_token: userData.access_token,
      admin_id: userData.id,
    };
    await Axios({
      method: "POST",
      url: "https://samasecuritysystems.com/apis/samaaAdmin/admin/category/update_category.php",
      data: data_send,
    })
      .then((res) => {
        console.log(res);
        if (res.status == "success") {
          getCategories();
          setOpenEdit(false);
          toast.success(res.message);
        } else {
          toast.error(res?.message);
        }
      })
      .finally(setLoader(false));
  };

  const handleUplaoadImage = () => {
    setuploadloading(true);
    const formData = new FormData();
    formData.append('image', img);
    axios
      .post(BASE_URL + 'upload.php', formData)
      .then((res) => {
        console.log(res);
        console.log(res.data.message);
        if (res.data.status == 'success') {
          setRowData({ ...rowData, image: res.data.message });
          toast.success('تم الرفع');
        } else if (res.data.status == 'error') {
          toast.error(res.data.message);
        } else {
          toast.error('حدث خطأ ما');
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setuploadloading(false);
      });
  };
  const handleUplaoadImageadd = () => {
    setuploadloading(true);
    const formData = new FormData();
    formData.append('image', img);
    axios
      .post(BASE_URL + 'upload.php', formData)
      .then((res) => {
        // console.log(res)
        // console.log(res.data.message)
        if (res.data.status == 'success') {
          setCategoryData({ ...categoryData, image: res.data.message });
          toast.success('تم الرفع');
        } else if (res.data.status == 'error') {
          toast.error(res.data.message);
        } else {
          toast.error('حدث خطأ ما');
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setuploadloading(false);
      });
  };

  useEffect(() => {
    getCategories();
  }, []);
  return (
    <div className="h-container con-h">
      <DefaultLayout
        children={
          <div className="childs">
            <div className="breadCrumbsFlex">
              <ContentNav head={"الفئات"} />
              <button
                className="btn addbtn btn-primary"
                onClick={() => setOpenAdd(true)}
              >
                <img
                  src="https://res.cloudinary.com/duovxefh6/image/upload/v1698247380/insert_mox69q.png"
                  alt=""
                />
                <span>إضافة فئة</span>
              </button>
            </div>
            <Table headers={headers} body={data} classess={["table-tc"]} />
            <Modal
              open={openAdd}
              toggle={setOpenAdd}
              headerTitle={"إضافة فئة"}
              children={
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    return !loader ? addCategories() : null;
                  }}
                >
                  <div className="inputField">
                    <label htmlFor="">عنوان الفئة</label>
                    <input
                      type="text"
                      onChange={(e) => {
                        setCategoryData({
                          ...categoryData,
                          name: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="inputField">
                    <label htmlFor="">وصف الفئة</label>
                    <textarea
                      type="text"
                      onChange={(e) => {
                        setCategoryData({
                          ...categoryData,
                          description: e.target.value,
                        });
                      }}
                    ></textarea>
                  </div>
                  <div className="input_field mb-2">
                    <label htmlFor="">صورة الفئة</label>
                    <input
                      type="file"
                      className="form-control"
                      onChange={(e) => {
                        setImg(e.target.files[0]);
                      }}
                    />
                    <div>
                      {uploadloading ? (
                        <Loader />
                      ) : (
                        <button
                          onClick={() => {
                            handleUplaoadImageadd();
                          }}
                          style={{ width: '100px' }}
                          className="btn mt-2 btn-success"
                        >
                          رفع
                        </button>
                      )}
                    </div>
                  </div>

                  {!loader ? (
                    <button className="btn btn-success"> إضافة</button>
                  ) : (
                    <Loader />
                  )}
                </form>
              }
            />

            <Modal
              open={openEdit}
              toggle={setOpenEdit}
              headerTitle={"تعديل فئة"}
              children={
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    return !loader ? editCategory() : null;
                  }}
                >
                  <div className="inputField">
                    <label htmlFor="">عنوان الفئة</label>
                    <input
                      type="text"
                      value={rowData?.name}
                      onChange={(e) => {
                        setRowData({
                          ...rowData,
                          name: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="inputField">
                    <label htmlFor="">وصف الفئة</label>
                    <textarea
                      type="text"
                      value={rowData?.description}
                      onChange={(e) => {
                        setRowData({
                          ...rowData,
                          description: e.target.value,
                        });
                      }}
                    ></textarea>
                  </div>
                  <div className="input_field mb-2">
                    <label htmlFor="">صورة الفئة</label>
                    <input
                      type="file"
                      className="form-control"
                      onChange={(e) => {
                        setImg(e.target.files[0]);
                      }}
                    />
                    <div>
                      {uploadloading ? (
                        <Loader />
                      ) : (
                        <button
                          onClick={() => {
                            handleUplaoadImage();
                          }}
                          style={{ width: '100px' }}
                          className="btn mt-2 btn-success"
                        >
                          رفع
                        </button>
                      )}
                    </div>
                  </div>
                  {!loader ? (
                    <button className="btn btn-success"> تعديل</button>
                  ) : (
                    <Loader />
                  )}
                </form>
              }
            />
            <Modal
              open={showStatusModal}
              toggle={setShowStatusModal}
              headerTitle={"إخفاء أوإظهار فئة"}
              children={
                <>
                  <p>
                    هل تريد حقا {rowData.hidden == '0' ? 'إخفاء' : 'إظهار'} هذا
                    المنتج
                  </p>
                  <div className="actions align-items-center d-flex gap-2">
                    {loader ? (
                      <Loader />
                    ) : (
                      <button
                        onClick={() => {
                          handleChangetStatus();
                        }}
                        className="btn btn-success"
                      >
                        نعم
                      </button>
                    )}
                    <button
                      onClick={() => {
                        setShowStatusModal(false);
                      }}
                      className="btn btn-danger"
                    >
                      لا
                    </button>
                  </div>
                </>
              }
            />
          </div>
        }
      />
    </div>
  );
}

export default Categories;
