export const categoriesData=[
  {
    category_id:1,
    category_name:'كاميرات',
    category_img:require("../../assets/images/cat1.png"),
  },
  {
    category_id:2,
    category_name:'بوابات امنيه',
    category_img:require("../../assets/images/cat2.jpeg"),
  },
]
