import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layout/defaultlayout";
import ContentNav from "../../datanavcontent";
import Table from "../../components/table";
import Modal from "../../components/modal";
import axios from "axios";
import { Axios } from "../../components/axios";
import { add, edit, hidde, show } from "../../navbar/sidenav/svgImages";
import { toast } from "react-toastify";
import Loader from "../../components/loader";
import { BASE_URL } from "../../components/axios/BASE_URL";

function Clients() {
  const [img, setImg] = useState();
  const [loader, setLoader] = useState(null);
  const [newData, setNewData] = useState({});
  const [uploadloading, setuploadloading] = useState(false);
  const [rowData, setRowData] = useState({});
  const [openEdit, setOpenEdit] = useState();
  const [openAdd, setOpenAdd] = useState();
  let localData = localStorage.getItem("samaa_admin");
  let userData = localData && JSON.parse(localData);
  const [pageLoading, setPageLoading] = useState(false);
  const [data, setData] = useState();
  const [changeLoading, setChangeLoading] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);

  const handleUplaoadImageafterEdit = () => {
    setuploadloading(true);
    const formData = new FormData();
    formData.append('image', img);
    axios
      .post(BASE_URL + 'upload.php', formData)
      .then((res) => {
        console.log(res.data.message);
        if (res.data.status == 'success') {
          setRowData({ ...rowData, logo: res.data.message });
          toast.success('تم الرفع');
        } else if (res.data.status == 'error') {
          toast.error(res.data.message);
        } else {
          toast.error('حدث خطأ ما');
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setuploadloading(false);
      });
  };

  const handleChangetStatus = async () => {
    setChangeLoading(true);
    const data_send = {
      ...rowData,
      access_token: userData.access_token,
      admin_id: userData.id,
    };

    if (data_send?.hidden == 0) {
      data_send.hidden = "1";
    } else {
      data_send.hidden = "0";
    }

    await Axios({
      method: "POST",
      url: "https://samasecuritysystems.com/apis/samaaAdmin/admin/clients/update_status.php",
      data: data_send,
    })
      .then((res) => {
        // console.log(res)
        if (res.status == 'success') {
          toast.success(res.message);
          getMessages();
          setShowStatusModal(false);
        } else if (res.status == 'error') {
          toast.error(res.message);
        } else {
          toast.error('حدث خطأ ما');
        }
      })
      .finally(setChangeLoading(false));
  };
  const getMessages = () => {
    setPageLoading(true);

    Axios({
      method: 'POST',
      url: "https://samasecuritysystems.com/apis/samaaAdmin/admin/clients/index.php",
    })
      .then((res) => {
        console.log(res, "ew");
        if (res.status == 'success') {
          setData(res?.message);
        } else if (res.status == 'error') {
          // localStorage.removeItem('samaa_admin');
          // window.location.reload();
          toast.error(res.message);
        }
      })
      .finally(() => {
        setPageLoading(false);
      });
  };
  useEffect(() => {
    getMessages();
  }, []);

  const headers = [
    {
      label: "رقم العميل",
      dataIndex: "id",
    },
    {
      label: "صورة العميل",
      dataIndex: "image",
      type: 'children',
      children: ({ row, headers }) => {
        return <img style={{ width: '100px' }} src={row?.logo} alt="" />;
      },
    },
    {
      label: "إسم الشخص",
      dataIndex: "company_name",
    },
    {
      label: "عنوان العميل",
      dataIndex: "location",
    },
    {
      label: "موقع العميل على الانترنت",
      dataIndex: "company_url",
      type: 'children',
      children: ({ row, headers }) => {
        return (
          <a
            href={
              !row?.company_url?.startsWith("http://") &&
              !row?.company_url?.startsWith("https://")
                ? "https://" + row?.company_url
                : row?.company_url
            }
            target="_blank"
          >
            الاطلاع على الموقع
          </a>
        );
      },
    },
    {
      label: "الأدوات",
      dataIndex: '',
      type: 'children',
      children: ({ row, header }) => {
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '4px',
              flexWrap: 'wrap',
            }}
          >
            <span
              onClick={() => {
                setRowData(row);
                setOpenEdit(true);
              }}
              style={{ color: 'green', cursor: 'pointer' }}
            >
              {edit}
            </span>
            {row.hidden == '0' ? (
              <span
                onClick={() => {
                  setRowData(row);
                  setShowStatusModal(true);
                }}
                style={{ color: 'green', cursor: 'pointer' }}
              >
                {show}
              </span>
            ) : (
              <span
                onClick={() => {
                  setRowData(row);
                  setShowStatusModal(true);
                }}
                style={{ color: 'red', cursor: 'pointer' }}
              >
                {hidde}
              </span>
            )}
          </div>
        );
      },
    },
  ];
  const handleUplaoadImage = () => {
    setuploadloading(true);
    const formData = new FormData();
    formData.append('image', img);
    axios
      .post(BASE_URL + 'upload.php', formData)
      .then((res) => {
        // console.log(res)
        console.log(res.data.message);
        if (res.data.status == 'success') {
          setNewData({ ...newData, image: res.data.message });
          toast.success('تم الرفع');
        } else if (res.data.status == 'error') {
          toast.error(res.data.message);
        } else {
          toast.error('حدث خطأ ما');
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setuploadloading(false);
      });
  };
  const addTestMonial = async () => {
    setLoader(true);
    const data_send = {
      ...newData,
      access_token: userData.access_token,
      admin_id: userData.id,
    };
    if (
      !data_send?.company_url?.startsWith("http://") &&
      !data_send?.company_url?.startsWith("https://")
    ) {
      toast.error("الرجاء إدخال رابط صالح يبدأ بhttp:// أو https://");
      setLoader(false);
      return;
    }
    await Axios({
      method: "POST",
      url: "https://samasecuritysystems.com/apis/samaaAdmin/admin/clients/add_client.php",
      data: data_send,
    })
      .then((res) => {
        console.log(res);
        if (res.status == "success") {
          getMessages();
          setOpenAdd(false);
          toast.success("تمت الإضافة بنجاح");
        } else {
          toast.error(res?.message);
        }
      })
      .finally(setLoader(false));
  };
  const editTest = async () => {
    setLoader(true);
    const data_send = {
      ...rowData,
      access_token: userData.access_token,
      admin_id: userData.id,
    };

    await Axios({
      method: "POST",
      url: "https://samasecuritysystems.com/apis/samaaAdmin/admin/clients/update_client.php",
      data: data_send,
    })
      .then((res) => {
        if (res.status == "success") {
          getMessages();
          setOpenEdit(false);
          toast.success(res?.message);
        } else {
          toast.error(res?.message);
        }
      })
      .finally(setLoader(false));
  };
  return (
    <div className="h-container con-h">
      <DefaultLayout
        children={
          <div className="childs">
            <div className="d-flex justify-content-between">
              <ContentNav head={"عملاؤنا"} />
              <span
                onClick={() => {
                  setOpenAdd(true);
                }}
                style={{ cursor: 'pointer', color: 'green', fontSize: '22px' }}
              >
                {add}
              </span>
            </div>
            <Table headers={headers} body={data} classess={["table-tc"]} />
          </div>
        }
      />
      <Modal
        open={showStatusModal}
        toggle={setShowStatusModal}
        headerTitle={"تغيير حالة الظهور"}
        children={
          <>
            <p>
              هل تريد حقا {rowData.hidden == '0' ? 'إخفاء' : 'إظهار'} هذا العميل
            </p>
            <div className="actions align-items-center d-flex gap-2">
              {changeLoading ? (
                <Loader />
              ) : (
                <button
                  onClick={() => {
                    handleChangetStatus();
                  }}
                  className="btn btn-success"
                >
                  نعم
                </button>
              )}
              <button
                onClick={() => {
                  setShowStatusModal(false);
                }}
                className="btn btn-danger"
              >
                لا
              </button>
            </div>
          </>
        }
      />
      <Modal
        open={openAdd}
        toggle={setOpenAdd}
        headerTitle={"إضافة عميل"}
        children={
          <form
            onSubmit={(e) => {
              e.preventDefault();
              return !loader ? addTestMonial() : null;
            }}
          >
            <div className="inputField">
              <label htmlFor="">إسم العميل</label>
              <input
                type="text"
                onChange={(e) => {
                  setNewData({ ...newData, company_name: e.target.value });
                }}
              />
            </div>
            <div className="inputField">
              <label htmlFor="">موقع العميل على الانترنت</label>
              <input
                type="text"
                onChange={(e) => {
                  setNewData({ ...newData, company_url: e.target.value });
                }}
              />
            </div>
            <div className="inputField">
              <label htmlFor="">عنوان العميل</label>
              <textarea
                type="text"
                onChange={(e) => {
                  setNewData({ ...newData, location: e.target.value });
                }}
              ></textarea>
            </div>
            <div className="input_field mb-2">
              <label htmlFor="">صورة العميل</label>
              <input
                type="file"
                className="form-control"
                onChange={(e) => {
                  setImg(e.target.files[0]);
                }}
              />
              <div>
                {uploadloading ? (
                  <Loader />
                ) : (
                  <button
                    onClick={() => {
                      handleUplaoadImage();
                    }}
                    style={{ width: '100px' }}
                    className="btn mt-2 btn-success"
                  >
                    رفع
                  </button>
                )}
              </div>
            </div>
            {!loader ? (
              <button className="btn btn-success"> إضافة</button>
            ) : (
              <Loader />
            )}
          </form>
        }
      />
      <Modal
        open={openEdit}
        toggle={setOpenEdit}
        headerTitle={"تعديل عميل"}
        children={
          <form
            onSubmit={(e) => {
              e.preventDefault();
              return !loader ? editTest() : null;
            }}
          >
            <div className="inputField">
              <label htmlFor="">إسم العميل</label>
              <input
                type="text"
                value={rowData?.company_name}
                onChange={(e) => {
                  setRowData({ ...rowData, company_name: e.target.value });
                }}
              />
            </div>
            <div className="inputField">
              <label htmlFor="">موقع العميل على الانترنت</label>
              <input
                type="text"
                value={rowData?.company_url}
                onChange={(e) => {
                  setRowData({ ...rowData, company_url: e.target.value });
                }}
              />
            </div>
            <div className="inputField">
              <label htmlFor="">عنوان العميل</label>
              <textarea
                type="text"
                value={rowData?.location}
                onChange={(e) => {
                  setRowData({ ...rowData, location: e.target.value });
                }}
              ></textarea>
            </div>
            <div className="input_field mb-2">
              <label htmlFor="">صورة العميل</label>
              <input
                type="file"
                className="form-control"
                onChange={(e) => {
                  setImg(e.target.files[0]);
                }}
              />
              <div>
                {uploadloading ? (
                  <Loader />
                ) : (
                  <button
                    onClick={() => {
                      handleUplaoadImageafterEdit();
                    }}
                    style={{ width: '100px' }}
                    className="btn mt-2 btn-success"
                  >
                    رفع
                  </button>
                )}
              </div>
            </div>
            {!loader ? (
              <button className="btn btn-success"> تعديل</button>
            ) : (
              <Loader />
            )}
          </form>
        }
      />
    </div>
  );
}

export default Clients;
