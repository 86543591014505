export const featuresData=[
  {
    feature_id:1,
    feature_name:'خدمه طوال اليوم',
    feature_image:require("../../assets/images/settings.png"),
    feature_des:'لدينا دعم فنى على أعلى المستوى',
  },
  {
    feature_id:2,
    feature_name:'جوده المنتجات',
    feature_image:require("../../assets/images/cart.png"),
    feature_des:'نتميز بمتجاتنا الأمنه',
  },
  {
    feature_id:3,
    feature_name:'فريق مميز',
    feature_image:require("../../assets/images/team.png"),
    feature_des:'نمتلك كوادر فنيه',
  },
]
