import React, { Fragment, useState } from "react";
import "./style.css";
import { Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  LoggedIn,
  fakeCredintials,
  loggedInData,
} from "../../data/fakeLoginData";
import { Axios } from "../../components/axios/index";
import { API_ROUTES, BASE_URL, BASES_ROUTES } from '../../components/axios/BASE_URL';
import Loader from "../../components/loader";
function Login() {
  const [loading,setLoading]=useState(false)
  const [loginData,setLoginData]=useState({
    email:'',
    password:'',
  });
  const login = (e) => {
    setLoading(true)
    console.log(loginData)
    Axios({
      method: 'POST',
      url: BASE_URL + API_ROUTES?.auth,
      data: { ...loginData },
      // timeout: 8000,
  }).then((response) => {
      console.log(response)
      if (response?.status === 'success') {
          toast.success('Success!');
          localStorage.setItem('samaa_admin', JSON.stringify(response?.message));
          window.location.reload();
      } else {
          toast.error(response?.message);
      }
  }).finally(()=>{
    setLoading(false)
  })
  };

  return (
    <Fragment>
      <div className="authContainer">
        <div className="authFunction">
          <form
            action=""
            onSubmit={(e) => {
              e.preventDefault();
              login(e);
            }}
          >
            <div className="inputField">
              <label htmlFor="email">الإيميل</label>
              <input onChange={(e)=>{
                setLoginData({...loginData,email:e.target.value})
              }} type="text" id="email" name="email" />
            </div>
            <div className="inputField">
              <label htmlFor="password">كلمة السر</label>
              <input onChange={(e)=>{
                setLoginData({...loginData,password:e.target.value})
              }} type="password" id="password" name="password" />
            </div>

            {
              loading?(
                <Loader/>
              ):(
                <button className="btn btn-success">تسجيل الدخول</button>
              )
            }
          </form>
        </div>
      </div>
    </Fragment>
  );
}

export default Login;
