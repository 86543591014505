import React, { Fragment, useEffect, useState } from "react";
import { SideNavData } from "../sidenav/sidenavdata";
import { NavLink, Navigate } from "react-router-dom";
import "./style.css";
import { Icon } from "@iconify/react";
import axios from "axios";
function SideNavbar({ active, setActive }) {
  return (
    <Fragment>
      <aside className={active ? "active" : ""}>
        <div className="list-close" onClick={() => setActive(false)}>
          <img
            src="https://res.cloudinary.com/duovxefh6/image/upload/v1693603266/close_bphmao.png"
            alt=""
            width={"24px"}
            style={{ marginLeft: "20px" }}
          />
        </div>
        <div className="logo-ic">
          <img
            src={
              require("../../assets/logo/logo.png")
            }
            alt=""
          />
        </div>
        <div className="links">
          {SideNavData.map((item, index) => {
            return (
              <NavLink
                to={item.path}
                onClick={() => setActive(false)}
                key={index}
              >
                <span>{item.icon} </span>
                <em>{item.label}</em>
              </NavLink>
            );
          })}
        </div>
      </aside>
    </Fragment>
  );
}

export default SideNavbar;
